import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pvdsPopoverBox: {
      width: 424,
      height: 364,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid #DADEE7",
      boxShadow: "0px 8px 32px - 8px rgba(0, 0, 0, 0.32)",
      borderRadius: "10px",
    },
    pvdsSearch: {
      overflow: "hidden",
    },
    pvdsTextField: {
      marginTop: theme.spacing(4),
      zIndex: 1500,
      width: 392,
      height: 40,
      overflow: "auto",
    },
    pvdsBox: {
      height: "17%",
      width: "100%",
    },
    pvdsButtonBox: {
      height: "100%",
      justifyContent: "space-between",
      display: "flex",
    },
    pvdsSelectClearBox: {
      display: "flex",
      alignItems: "center",
      width: "65%",
    },
    pvdsAddSaveFilterBox: {
      marginRight: theme.spacing(4),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pvdsSelectAllButton: {
      marginLeft: theme.spacing(4),
      letterSpacing: "0.01em",
      padding: "0",
      width: "30%",
      minWidth: "30%",
      textTransform: "capitalize",
    },
    pvdssClearSelectionButton: {
      letterSpacing: "0.01em",
      padding: "0",
      width: "50%",
      minWidth: "50%",
      textTransform: "capitalize",
    },
    pvdAddFilterButton: {
      width: "100%",
      minWidth: "100%",
      textTransform: "capitalize",
    },
    pvdsAutocomplete: {
      boxShadow: "none",
      border: "0px",
      width: "100%",
      height: 235,
      marginTop: "12.5%",
    },
    pvdsMenuOption: {
      '&[data-focus="true"]': {
        backgroundColor: "white",
      },
      height: 37,
      marginTop: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    pvdsMenu: {
      height: "100%",
      marginTop: theme.spacing(3),
    },
  })
);

export default useStyles;

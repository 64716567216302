import React from "react";
import { getStalledTransactions } from "../../../Api";
import withData from "../../Data/withData";
import BaseView from "../common/BaseView";
import { columns, columnsPVD } from "./config";

function View(props: any) {
  return (
    <BaseView
      columns={columns}
      columnsPVD={columnsPVD}
      title="Stalled Transactions"
      subtitle="These are companies that stalled during underwriting or contract creation. Every effort is done to revive these opportunities, and we will notify you immediately when that happens."
      {...props}
    ></BaseView>
  );
}

export default withData(View, getStalledTransactions);

import React from "react";
import { getAllInventory } from "../../../Api";
import withData from "../../Data/withData";
import BaseView from "../../DealPipeline/common/BaseView";
import { columns, columnsPVD } from "./config";

function View(props: any) {
  return (
    <BaseView
      columns={columns}
      columnsPVD={columnsPVD}
      title="All Inventory"
      subtitle="Taycor works diligently to provide accurate accounting of equipment inventory that has been sold. If there are any discrepancies to what you see below, please reach out to your relationship manager."
      {...props}
    ></BaseView>
  );
}

export default withData(View, getAllInventory);

import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: 42,
      width: "100%",
      textAlign: "center",
    },
    table: {
      marginTop: 34,
      "&.MuiPaper-elevation1": {
        boxShadow: "none",
      },
      "&.MuiPaper-rounded": {
        borderRadius: 0,
      },
      "& .MuiTableCell-root": {
        borderBottom: "none",
        padding: "16px 0px 16px 0px",
      },
    },
    tableIndicatorCell: {
      minWidth: 11,
      width: 11,
      "&.MuiTableCell-root": {
        paddingLeft: 73,
        [theme.breakpoints.down("md")]: {
          paddingLeft: theme.spacing(4),
        },
      },
    },
    tableStatusCell: {
      minWidth: 115,
      "& h6": {
        fontWeight: "bold",
      },
      "&.MuiTableCell-root": {
        paddingLeft: 20,
        [theme.breakpoints.down("md")]: {
          paddingLeft: theme.spacing(2),
        },
      },
    },
    tableDateCell: {
      minWidth: 91,
      "&.MuiTableCell-root": {
        paddingLeft: theme.spacing(9),
        [theme.breakpoints.down("md")]: {
          paddingLeft: theme.spacing(2),
        },
      },
    },
    tableTimeCell: {
      minWidth: 66,
      "& h6": {
        color: theme.colors.milestonesTime,
      },
      "&.MuiTableCell-root": {
        paddingLeft: 27,
        paddingRight: 45,
        [theme.breakpoints.down("md")]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(4),
        },
      },
    },
    colorIndicator: {
      width: 11,
      height: 11,
      borderRadius: 5.5,
      zIndex: 1,
      position: "relative",
    },
    connector: {
      position: "absolute",
      width: 3,
      backgroundColor: theme.colors.lightGrey,
      top: 253,
      zIndex: 0,
      left: 104,
      [theme.breakpoints.down("md")]: {
        left: 47,
      },
    },
  })
);

export const connectorHeight = 57;

export default useStyles;

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Tab,
  Tabs,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { getCompanyAndTaycorRep, getMilestones } from "../../../Api";
import { ActivityType } from "../../../Helpers/types";
import Company from "./children/Company";
import DocUploader from "./children/DocUploader";
import Milestones from "./children/Milestones";
import TabPanel from "./children/TabPanel";
import useStyles from "./details.style";

const isNotStalledLeadOrTransaction = (activityType: ActivityType, item: any) =>
  (activityType === "lead" &&
    !(item.status === "Stalled" && item.leadStatusWc === "Stalled")) ||
  (activityType === "transaction" &&
    !(item.acctOutlook === "stalled" || item.acctOutlook === "dead"));

interface DetailsProps {
  activityId: string;
  activityType: ActivityType;
  onCloseDrawer: () => void;
}

function Details(props: DetailsProps) {
  const { activityId, activityType } = props;
  const classes = useStyles();

  const [companyAndRep, setCompanyAndRep] = useState<any>({});
  const [milestones, setMilestones] = useState<any>({});
  const [isLoadingCompanyAndRep, setIsLoadingCompanyAndRep] =
    useState<boolean>(false);
  const [isLoadingMilestones, setIsLoadingMilestones] =
    useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [showDocUploader, setShowDocUploader] = useState<boolean>(false);

  useEffect(() => {
    setIsLoadingCompanyAndRep(true);
    setIsLoadingMilestones(true);

    getCompanyAndTaycorRep(
      activityId,
      activityType,
      (data) => {
        setCompanyAndRep(data);
        setShowDocUploader(isNotStalledLeadOrTransaction(activityType, data));
        setIsLoadingCompanyAndRep(false);
      },
      () => {
        setCompanyAndRep({});
        setShowDocUploader(false);
        setIsLoadingCompanyAndRep(false);
      }
    );
    getMilestones(
      activityId,
      activityType,
      (data) => {
        setMilestones(data);
        setIsLoadingMilestones(false);
      },
      () => {
        setMilestones({});
        setIsLoadingMilestones(false);
      }
    );
  }, [activityId, activityType]);

  return (
    <Drawer
      anchor="right"
      open
      onClose={() => props.onCloseDrawer()}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <SimpleBar className={classes.simplebar}>
        <Box className={classes.closeIcon}>
          <Button
            onClick={() => props.onCloseDrawer()}
            startIcon={<CloseIcon></CloseIcon>}
            variant="contained"
            color="secondary"
          >
            <></>
          </Button>
        </Box>
        <Tabs
          value={tab}
          indicatorColor="secondary"
          onChange={(event: any, newValue: number) => {
            setTab(newValue);
          }}
          className={classes.tabs}
        >
          <Tab label="Details" />
          {showDocUploader && <Tab label="Doc Uploader" />}
          <Tab label="Milestones" />
        </Tabs>
        <TabPanel value={tab} index={0} className={classes.tabPanel}>
          {isLoadingCompanyAndRep && <CircularProgress></CircularProgress>}
          {!isLoadingCompanyAndRep && (
            <Company
              company={companyAndRep}
              activityType={props.activityType}
            ></Company>
          )}
        </TabPanel>
        {tab === 1 && showDocUploader && (
          <TabPanel value={tab} index={1} className={classes.tabPanel}>
            <DocUploader
              activityId={activityId}
              activityType={activityType as "lead" | "transaction"}
              taycorRep={companyAndRep.taycorRep}
            ></DocUploader>
          </TabPanel>
        )}
        <TabPanel
          value={tab}
          index={showDocUploader ? 2 : 1}
          className={classes.tabPanel}
        >
          {isLoadingMilestones && <CircularProgress></CircularProgress>}
          {!isLoadingMilestones && (
            <Milestones
              milestones={milestones}
              activityType={props.activityType}
            ></Milestones>
          )}
        </TabPanel>
      </SimpleBar>
    </Drawer>
  );
}

export default Details;

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { scrollbar } from "../../../Theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...scrollbar,
    },
    rootDrawerOpen: {
      "& .simplebar-vertical": {
        position: "fixed",
        right: theme.layout.rightDrawerWidth,
        [theme.breakpoints.down("md")]: {
          right: theme.layout.rightDrawerNarrowWidth,
        },
      },
    },
    tableContainer: {
      marginLeft: theme.pipelines.spacing.left,
      marginRight: theme.pipelines.spacing.right,
      marginTop: 40,
      [theme.breakpoints.down("md")]: {
        marginLeft: 10,
      },
      "& div.MuiPaper-root": {
        // table width
        width: `calc(100vw - ${theme.layout.leftDrawerWidth}px - 102px)`,
        [theme.breakpoints.down("sm")]: {
          width: `calc(100vw - 102px)`,
        },
        // left align title text
        "& > div.MuiToolbar-root": {
          paddingLeft: 0,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse",
          },
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          // title
          "& > div:nth-child(1)": {
            flex: "1 1 56%",
          },
          // spacer
          "& > div:nth-child(2)": {
            flex: "1 1 0%",
          },
          // search
          "& > div:nth-child(3)": {
            overflow: "hidden",
          },
        },
        // box shadow and margin for table content
        "& > div:nth-child(2)": {
          marginTop: theme.spacing(12),
          boxShadow: "0px 6px 20px 0 rgba(193, 207, 217, 0.2)",
          borderRadius: 10,
          paddingLeft: theme.spacing(4),
          // fixed columns
          "& > div > div > div:nth-child(2)": {
            boxShadow: "none !important",
            paddingLeft: theme.spacing(4),
          },
        },
        // center align pagination
        "& td.MuiTablePagination-root": {
          border: "none",
          "& div.MuiTablePagination-toolbar": {
            display: "flex",
            justifyContent: "center",
            "& div.MuiTablePagination-spacer": {
              flex: 0,
            },
          },
        },
        // table header
        "& table th": {
          fontSize: 13,
          color: "#111111",
          paddingTop: 15,
          paddingBottom: 13,
          "&:first-child": {
            paddingLeft: 0,
            "& .MuiTableSortLabel-root": {
              paddingLeft: theme.spacing(5),
            },
          },
          "& .MuiTableSortLabel-root": {
            height: 42,
            paddingLeft: theme.spacing(1),
            "& svg.MuiSvgIcon-root.MuiTableSortLabel-icon": {
              color: theme.palette.secondary.main,
            },
          },
          "& .MuiTableSortLabel-active": {
            fontSize: 14,
            backgroundColor: theme.colors.lightGrey,
          },
        },
        // table content row
        "& table tbody tr": {
          height: theme.spacing(14),
        },
        // // table content row hover
        // "& table:first-child tbody tr:hover": {
        //   color: `${theme.colors.darkBlue} !important`,
        //   backgroundColor: `${theme.colors.hoverBackground} !important`,
        // },
        // cell style
        "& table tr td": {
          fontSize: 13,
          fontWeight: 300,
          fontStretch: " normal",
          fontStyle: "normal",
          lineHeight: "18px",
          letterSpacing: "0.26px",
        },
      },
    },

    tableContainerMenuCollapsed: {
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
      "& div.MuiPaper-root": {
        // table width
        width: `calc(100vw - ${theme.layout.menuWidth}px - 102px)`,
        [theme.breakpoints.down("md")]: {
          width: `calc(100vw - 20px)`,
        },
      },
    },
    tableContainerNarrow: {
      "& div.MuiPaper-root": {
        // table width
        width: `calc(100vw - ${theme.layout.leftDrawerWidth}px - ${theme.layout.rightDrawerWidth}px - 66px)`,
        [theme.breakpoints.down("md")]: {
          width: `calc(100vw - ${theme.layout.leftDrawerWidth}px - ${theme.layout.rightDrawerNarrowWidth}px - 66px)`,
        },
      },
    },
    tableContainerNarrowMenuCollapsed: {
      "& div.MuiPaper-root": {
        // table width
        width: `calc(100vw - ${theme.layout.menuWidth}px - ${theme.layout.rightDrawerWidth}px - 66px)`,
        [theme.breakpoints.down("md")]: {
          width: `calc(100vw - ${theme.layout.menuWidth}px - ${theme.layout.rightDrawerNarrowWidth}px - 66px)`,
        },
      },
    },
    tableContainerIntegration: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 48,
      paddingRight: 54,
      "& div.MuiPaper-root": {
        // table width
        width: `100%`,
        [theme.breakpoints.down("md")]: {
          width: `calc(100vw - 20px)`,
        },
      },
    },
    tableSubtitle: {
      marginTop: theme.spacing(2),
    },
    actions: {
      padding: theme.spacing(0, 0, 4),
      display: "flex",
      flexDirection: "row",
    },
    actionsLabel: {
      margin: theme.spacing(2, 2, 2, 0),
    },
    actionsButton: {
      marginTop: theme.spacing(1),
      margin: theme.spacing(2),
      color: theme.palette.common.white,
      textTransform: "capitalize",
      width: 200,
    },
    autocomplete: {
      width: 400,
    },
    autocompleteOption: {
      color: theme.palette.common.black,
    },
    simplebar: {
      "& .simplebar-content-wrapper": {
        overflow: "hidden !important",
      },
      "& .simplebar-content": {
        width: `calc(100vw - ${theme.layout.leftDrawerWidth}px - 102px)`,
        [theme.breakpoints.down("md")]: {
          width: `calc(100vw  - 10px)`,
        },
        [theme.breakpoints.down("sm")]: {
          width: `calc(100vw  - 40px)`,
        },
      },
    },
    simplebarIntegration: {
      "& .simplebar-content": {
        width: "99%",
        [theme.breakpoints.down("md")]: {
          width: `calc(100vw  - 10px)`,
        },
        [theme.breakpoints.down("sm")]: {
          width: `calc(100vw  - 40px)`,
        },
      },
    },
  })
);

export const tableStyle: React.CSSProperties = {
  border: "none",
  boxShadow: "none",
};

export const headerStyle: React.CSSProperties = {};

export const searchFieldStyle = (theme: Theme): React.CSSProperties => ({
  backgroundColor: theme.colors.lightGrey,
  height: 50,
  width: 351,
});

export default useStyles;

import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Switch,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import TimelineIcon from "@material-ui/icons/Timeline";
import ViewListIcon from "@material-ui/icons/ViewList";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useBrandedPortal } from "../../Helpers/hooks/useBrandedPortal";
import theme from "../../Theme";
import { AuthConsumer } from "../Auth";
import CopyIcon from "../Common/CopyIcon";
import Permission, { InventoryPermission } from "../Permission";
import useStyles, { menuItemTooltipStyle } from "./layoutWithMenu.style";

const MenuItemTooltip = withStyles((theme: Theme) => ({
  tooltip: menuItemTooltipStyle(theme),
}))(Tooltip);

interface MenuItem {
  title: string;
  url: string;
  icon: React.ReactElement;
}

interface LayoutWithMenuProps {
  menuExpanded: boolean;
  onToggleMenu: (expanded: boolean) => void;
  updateNotificationsToggle: () => void;
  notificationsToggle: boolean;
  children?: any;
}

function LayoutWithMenu(props: LayoutWithMenuProps) {
  const classes = useStyles();

  const {
    menuExpanded: drawerVisible,
    onToggleMenu: setDrawerVisible,
    updateNotificationsToggle,
    notificationsToggle,
  } = props;
  const history = useHistory();

  const [avatarMenu, setAvatarMenu] = React.useState<null | HTMLElement>(null);

  const brandedPortal = useBrandedPortal();
  const isBrandedPortal = brandedPortal.isBrandedPortal;

  const openAvatarMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarMenu(event.currentTarget);
  };

  const closeAvatarMenu = () => {
    setAvatarMenu(null);
  };
  const [contentKey, setContentKey] = useState<any>(0);

  const [copied, setCopied] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [copied]);

  const logoPath = drawerVisible
    ? isBrandedPortal
      ? brandedPortal.logoPath
      : "/powered_by_taycor_logo.png"
    : isBrandedPortal
    ? brandedPortal.logoSubmarkPath
    : "/powered_by_taycor_icon.png";

  const dashboardMenuItems: Array<MenuItem> = [
    {
      title: "My Pipeline",
      url: "/dashboard",
      icon: <TimelineIcon></TimelineIcon>,
    },
  ];
  const dealPipelinesMenuItems: Array<MenuItem> = [
    {
      title: "Active Leads",
      url: "/active-leads",
      icon: <FlashOnIcon></FlashOnIcon>,
    },
    {
      title: "Active Transactions",
      url: "/active-transactions",
      icon: <CheckCircleIcon></CheckCircleIcon>,
    },
    {
      title: "Funded Transactions",
      url: "/funded-transactions",
      icon: <AttachMoneyIcon></AttachMoneyIcon>,
    },
    {
      title: "All",
      url: "/all-leads",
      icon: <SupervisedUserCircleIcon></SupervisedUserCircleIcon>,
    },
    {
      title: "Stalled Leads",
      url: "/stalled-leads",
      icon: <Brightness4Icon></Brightness4Icon>,
    },
    {
      title: "Stalled Transactions",
      url: "/stalled-transactions",
      icon: <NightsStayIcon></NightsStayIcon>,
    },
  ];
  const inventoryTrackerMenuItems: Array<MenuItem> = [
    {
      title: "Active Inventory",
      url: "/inventory/active",
      icon: <CheckCircleIcon></CheckCircleIcon>,
    },
    {
      title: "Sold Inventory",
      url: "/inventory/sold",
      icon: <AttachMoneyIcon></AttachMoneyIcon>,
    },
    {
      title: "All Inventory",
      url: "/inventory/all",
      icon: <DoneAllIcon></DoneAllIcon>,
    },
  ];
  const userSetupMenuItems: Array<MenuItem> = [
    {
      title: "Add User",
      url: "/admin/add-user",
      icon: <PersonAddIcon></PersonAddIcon>,
    },
    {
      title: "See Existing Users",
      url: "/admin",
      icon: <PeopleAltIcon></PeopleAltIcon>,
    },
  ];

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const renderMenuHeader = (title: string, cssClass?: string) => (
    <MenuItem
      className={classnames(
        classes.drawerMenuItemHeader,
        {
          [classes.drawerMenuItemHeaderExpanded]: drawerVisible,
          [classes.drawerMenuItemHeaderCollapsed]: !drawerVisible,
        },
        cssClass
      )}
      disableRipple
    >
      <Typography variant="h5">{title}</Typography>
    </MenuItem>
  );

  const renderMenuItem = (menuItem: MenuItem, key: string | number) => (
    <MenuItem
      component={NavLink}
      to={menuItem.url}
      exact
      className={classnames(classes.drawerMenuItem, {
        [classes.drawerMenuItemExpanded]: drawerVisible,
        [classes.drawerMenuItemCollapsed]: !drawerVisible,
      })}
      activeClassName={classnames(classes.drawerMenuItemActive, {
        [classes.drawerMenuItemActiveCollapsed]: !drawerVisible,
      })}
      key={key}
      onClick={() => {
        isSmallScreen && setDrawerVisible(!drawerVisible);
        setContentKey(Math.random());
      }}
    >
      <ListItemIcon
        className={classnames({
          [classes.drawerMenuItemIconExpanded]: drawerVisible,
          [classes.drawerMenuItemIconCollapsed]: !drawerVisible,
        })}
      >
        {drawerVisible ? (
          <>{menuItem.icon}</>
        ) : (
          <MenuItemTooltip title={menuItem.title} placement="right">
            {menuItem.icon}
          </MenuItemTooltip>
        )}
      </ListItemIcon>
      <ListItemText
        primary={menuItem.title}
        primaryTypographyProps={{ variant: "h6" }}
      ></ListItemText>
      <Box
        className={classnames(classes.drawerMenuItemEnd, {
          [classes.drawerMenuItemEndOpen]: drawerVisible,
          [classes.drawerMenuItemEndClose]: !drawerVisible,
        })}
      ></Box>
    </MenuItem>
  );
  const renderMenuDivider = (isTop: boolean = false) => (
    <>
      <Box
        boxShadow={1}
        className={classnames(classes.dividerContainer, {
          [classes.notVisible]: !drawerVisible || isTop,
        })}
      >
        <Box boxShadow={2}>
          <Box boxShadow={3}></Box>
        </Box>
      </Box>
      <Divider
        className={classnames(classes.divider, {
          [classes.dividerExpanded]: drawerVisible,
          [classes.dividerCollapsed]: !drawerVisible,
          [classes.dividerCollapsedFull]: isTop,
          [classes.notVisible]: drawerVisible && isTop,
        })}
      ></Divider>
    </>
  );
  const copyLink = (financeApplicationUrl: string) => {
    if (financeApplicationUrl) {
      navigator.clipboard.writeText(financeApplicationUrl);
      setCopied(true);
    }
  };
  const newAppButtonClick = () => history.push("/finance-application");
  const newAppButton = (financeApplicationUrl: string) =>
    drawerVisible ? (
      <Box className={classes.newAppContainer}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.newAppButton}
          onClick={newAppButtonClick}
        >
          New Application
        </Button>
        <Tooltip title={copied ? "Copied!" : "Copy"}>
          <Button
            className={classes.copyApp}
            onClick={() => copyLink(financeApplicationUrl)}
            startIcon={<CopyIcon />}
          >
            Copy application link
          </Button>
        </Tooltip>
      </Box>
    ) : (
      <div className={classes.newAppCollapsed}>
        <MenuItemTooltip title="New Application" placement="right">
          <IconButton
            className={classes.newAppIcon}
            size="small"
            onClick={newAppButtonClick}
          >
            <AddBoxIcon></AddBoxIcon>
          </IconButton>
        </MenuItemTooltip>
        <MenuItemTooltip title="Copy application link" placement="right">
          <IconButton
            className={classes.collapsedAppIcon}
            size="small"
            onClick={() => copyLink(financeApplicationUrl)}
          >
            <CopyIcon />
          </IconButton>
        </MenuItemTooltip>
      </div>
    );

  const drawerContent = (
    <Box
      className={
        isBrandedPortal ? classes.drawerContentMidpoint : classes.drawerContent
      }
    >
      <Grid
        container
        className={classnames({
          [classes.drawerMenuContainerExpanded]: drawerVisible,
          [isBrandedPortal
            ? classes.drawerMenuContainerCollapsedMidpoint
            : classes.drawerMenuContainerCollapsed]: !drawerVisible,
        })}
      >
        <Grid item>
          <Box
            className={classnames(
              isBrandedPortal ? classes.drawerLogoMidpoint : classes.drawerLogo,
              {
                [classes.drawerLogoCollapsed]: !drawerVisible,
              }
            )}
          >
            <NavLink to="/">
              <Avatar variant="square" alt="TF" src={logoPath}></Avatar>
            </NavLink>
          </Box>
          <AuthConsumer>
            {({ isAuthenticated, getUser }) => {
              return isAuthenticated() &&
                (getUser()?.financeApplicationUrl?.length || 0) > 0 ? (
                <Box
                  className={classnames({
                    [classes.centerAlign]: drawerVisible,
                  })}
                >
                  {newAppButton(getUser()?.financeApplicationUrl || "")}
                </Box>
              ) : null;
            }}
          </AuthConsumer>
          <MenuList>
            {renderMenuHeader("Dashboard")}
            {dashboardMenuItems.map((menuItem, index) =>
              renderMenuItem(menuItem, index)
            )}
            <InventoryPermission>
              {[
                {
                  title: "My Inventory",
                  url: "/inventory/dashboard",
                  icon: <ViewListIcon></ViewListIcon>,
                },
              ].map((menuItem, index) => renderMenuItem(menuItem, index))}
            </InventoryPermission>
          </MenuList>
          {renderMenuDivider()}
          <MenuList>
            {renderMenuHeader("Deal Pipeline")}
            {dealPipelinesMenuItems.map((menuItem, index) =>
              renderMenuItem(menuItem, index)
            )}
          </MenuList>
          <InventoryPermission>
            {renderMenuDivider()}
            <MenuList>
              {renderMenuHeader("Inventory Tracker")}
              {inventoryTrackerMenuItems.map((menuItem, index) =>
                renderMenuItem(menuItem, index)
              )}
            </MenuList>
          </InventoryPermission>
          <Permission role="Admin">
            {renderMenuDivider()}
            <MenuList>
              {renderMenuHeader(
                "User Set Up",
                classes.drawerMenuItemHeaderAdmin
              )}
              {userSetupMenuItems.map((menuItem, index) =>
                renderMenuItem(menuItem, index)
              )}
            </MenuList>
          </Permission>
          <Permission role="Brand Admin">
            {renderMenuDivider()}
            <MenuList>
              {renderMenuHeader(
                "User Set Up",
                classes.drawerMenuItemHeaderAdmin
              )}
              {userSetupMenuItems.map((menuItem, index) =>
                renderMenuItem(menuItem, index)
              )}
            </MenuList>
          </Permission>
        </Grid>
        <Grid item className={classes.userAvatarContainer}>
          <AuthConsumer>
            {({ isAuthenticated, getUser, signout }) => {
              if (isAuthenticated()) {
                return (
                  <Box className={classes.userAvatarContent}>
                    <Avatar className={classes.userAvatarIcon}>
                      <div className={classes.userAvatarBorder}></div>
                      <AccountCircleIcon></AccountCircleIcon>
                    </Avatar>
                    <Box
                      className={classnames(classes.userAvatarName, {
                        [classes.hidden]: !drawerVisible,
                      })}
                    >
                      <Typography variant="body1">
                        {getUser()?.firstName}
                      </Typography>
                      <Typography variant="overline">
                        {getUser()?.companyName}
                      </Typography>
                    </Box>
                    <IconButton
                      size="small"
                      className={classnames(classes.userAvatarDots, {
                        [classes.userAvatarDotsCollapsed]: !drawerVisible,
                      })}
                      onClick={openAvatarMenu}
                    >
                      <MoreVertIcon></MoreVertIcon>
                    </IconButton>
                    <Menu
                      id="avatar-menu"
                      anchorEl={avatarMenu}
                      keepMounted
                      open={Boolean(avatarMenu)}
                      onClose={closeAvatarMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem onClick={() => signout()}>
                        <ListItemIcon>
                          <MeetingRoomIcon></MeetingRoomIcon>
                        </ListItemIcon>
                        <ListItemText>Log Out</ListItemText>
                      </MenuItem>
                      <MenuItem className={classes.notificationsToggle}>
                        <ListItemIcon>
                          <Switch
                            checked={notificationsToggle}
                            onChange={updateNotificationsToggle}
                            color="primary"
                            name="notificationsToggle"
                          />
                        </ListItemIcon>
                        <ListItemText>Notifications</ListItemText>
                      </MenuItem>
                      {getUser()?.apiClientId && (
                        <MenuItem component={Link} to="/user/apiaccess">
                          <ListItemIcon>
                            <VpnKeyIcon></VpnKeyIcon>
                          </ListItemIcon>
                          <ListItemText>API Access</ListItemText>
                        </MenuItem>
                      )}
                      <MenuItem>
                        <ListItemIcon>
                          <ContactSupportIcon></ContactSupportIcon>
                        </ListItemIcon>
                        <ListItemText>Support</ListItemText>
                      </MenuItem>
                    </Menu>
                  </Box>
                );
              } else {
                return <></>;
              }
            }}
          </AuthConsumer>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Container className={classes.root} maxWidth={false} disableGutters={true}>
      <Hidden smDown>
        <Drawer
          className={classnames(classes.leftDrawer, {
            [classes.leftDrawerOpen]: drawerVisible,
            [classes.leftDrawerClose]: !drawerVisible,
          })}
          classes={{
            paper: classnames(classes.leftDrawerPaper, {
              [classes.leftDrawerOpen]: drawerVisible,
              [classes.leftDrawerClose]: !drawerVisible,
            }),
          }}
          open
          variant="persistent"
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          className={classnames(classes.leftDrawer, {
            [classes.leftDrawerOpen]: drawerVisible,
          })}
          classes={{
            paper: classnames(classes.leftDrawerPaper, {
              [classes.leftDrawerOpen]: drawerVisible,
            }),
          }}
          open={drawerVisible}
          onClose={() => setDrawerVisible(!drawerVisible)}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Avatar
          className={classnames(classes.drawerToggle, {
            [classes.drawerToggleOpen]: drawerVisible,
            [classes.drawerToggleClose]: !drawerVisible,
          })}
          onClick={() => setDrawerVisible(!drawerVisible)}
        >
          {drawerVisible && <ChevronLeftIcon></ChevronLeftIcon>}
          {!drawerVisible && <ChevronRightIcon></ChevronRightIcon>}
        </Avatar>
      </Hidden>
      <Hidden mdUp>
        <Box className={classnames(classes.topMenuBar)}>
          <Avatar
            className={classnames(classes.drawerToggleMobile)}
            onClick={() => setDrawerVisible(!drawerVisible)}
          >
            {<MenuIcon fontSize="large"></MenuIcon>}
          </Avatar>
        </Box>
      </Hidden>
      <Box
        className={classnames(classes.contentContainer, {
          [classes.contentContainerExpanded]: drawerVisible && !isSmallScreen,
          [classes.contentContainerCollapsed]: !drawerVisible && !isSmallScreen,
        })}
      >
        <Box className={classnames(classes.content)} key={contentKey}>
          {props.children}
        </Box>
      </Box>
    </Container>
  );
}

export default LayoutWithMenu;

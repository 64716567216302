import React from "react";
import { Route } from "react-router-dom";
import { getUsers } from "../../Api";
import {
  getBrandIds,
  getBrands,
  getBrandUsers,
  getVendors,
} from "../../Api/portal-api";
import { allBrandIds } from "../../Helpers/constants";
import { useBrandedPortal } from "../../Helpers/hooks/useBrandedPortal";
import EditUser from "./EditUser";
import UserList from "./UserList";

interface AdminProps {
  url: string;
}

interface AdminState {
  data: Array<any>;
  isLoading: boolean;
  isError: boolean;
  selectedItem: any;
  itemWasUpdated: boolean;
  vendors: Array<any>;
  brands: Array<any>;
  brandIds: Array<any>;
  isLoadingVendors: boolean;
  isVendorsError: boolean;
}
const brandedPortal = useBrandedPortal();
const isBrandedPortal = brandedPortal.isBrandedPortal;

class Admin extends React.Component<AdminProps, AdminState> {
  constructor(props: AdminProps) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      isError: false,
      selectedItem: null,
      itemWasUpdated: false,
      vendors: [],
      brands: [],
      brandIds: [],
      isLoadingVendors: true,
      isVendorsError: false,
    };
  }
  loadUsers() {
    isBrandedPortal
      ? getBrandUsers(
          brandedPortal.brand,
          (data) => {
            this.setState({
              data: data,
              isLoading: false,
              isError: false,
              selectedItem: null,
              itemWasUpdated: false,
            });
          },
          (err) => {
            this.setState({
              isError: true,
              isLoading: false,
              data: [],
              selectedItem: null,
              itemWasUpdated: false,
            });
          }
        )
      : getUsers(
          (data) => {
            this.setState({
              data: data,
              isLoading: false,
              isError: false,
              selectedItem: null,
              itemWasUpdated: false,
            });
          },
          (err) => {
            this.setState({
              isError: true,
              isLoading: false,
              data: [],
              selectedItem: null,
              itemWasUpdated: false,
            });
          }
        );
  }

  loadVendors() {
    getVendors(
      (data) => {
        this.setState({
          vendors: data,
          isLoadingVendors: false,
          isVendorsError: false,
        });
      },
      (err) => {
        this.setState({
          isVendorsError: true,
          isLoadingVendors: false,
          vendors: [],
        });
      }
    );
  }

  loadBrands() {
    getBrands(
      (data) => {
        this.setState({
          brands: data,
        });
      },
      (err) => {
        this.setState({
          brands: [],
        });
      }
    );
  }

  loadBrandIds() {
    getBrandIds(
      (data) => {
        this.setState({
          brandIds: data.sort((a: any, b: any) =>
            b.name === allBrandIds.dbName ? 1 : -1
          ),
        });
      },
      (err) => {
        this.setState({
          brandIds: [],
        });
      }
    );
  }

  componentDidMount() {
    this.loadUsers();
    this.loadVendors();
    this.loadBrandIds();
    if (!isBrandedPortal) {
      this.loadBrands();
    }
  }

  componentDidUpdate() {
    if (this.state.itemWasUpdated) {
      this.loadUsers();
    }
  }

  render() {
    const onEditDoneHandler = () => {
      this.setState({ itemWasUpdated: true });
    };
    return (
      <>
        <Route path={`${this.props.url}/`} exact>
          <UserList
            data={this.state.data}
            isLoading={this.state.isLoading}
            isError={this.state.isError}
            onEditUser={(user) => this.setState({ selectedItem: user })}
          ></UserList>
        </Route>
        <Route path={`${this.props.url}/add-user`} exact>
          <EditUser
            brands={this.state.brands}
            brandIds={this.state.brandIds}
            vendors={this.state.vendors}
            onEditDone={onEditDoneHandler}
          ></EditUser>
        </Route>
        <Route path={`${this.props.url}/edit-user`} exact>
          <EditUser
            brands={this.state.brands}
            brandIds={this.state.brandIds}
            vendors={this.state.vendors}
            onEditDone={onEditDoneHandler}
            user={this.state.selectedItem}
          ></EditUser>
        </Route>
      </>
    );
  }
}

export default Admin;

import React from "react";

const chipStyle = (style: any): React.CSSProperties => ({
  ...style,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 170,
  height: 30,
  borderRadius: 3,
});

const avatarStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const avatarTextStyle: React.CSSProperties = {
  marginLeft: 8,
};

const centerAlignCellStyle: React.CSSProperties = {
  textAlign: "center",
};

export { chipStyle, avatarStyle, avatarTextStyle, centerAlignCellStyle };

import React from "react";
import { Box, Avatar } from "@material-ui/core";
import { AuthConsumer } from "../Auth";
import useStyles from "./companyLogo.style";
import classNames from "classnames";

interface CompanyLogoProps {
  menuExpanded: boolean;
}

function CompanyLogo(props: CompanyLogoProps) {
  const classes = useStyles();
  const isIntegration = window.location.href.indexOf("integration") !== -1;

  return (
    <Box
      className={classNames(classes.headerLogo, {
        [classes.logoMenuExpanded]: !props.menuExpanded,
        [classes.logoIntegration]: isIntegration,
      })}
    >
      <AuthConsumer>
        {({ getUser }) => (
          <Avatar
            src={getUser()?.companyLogo}
            alt="Company Logo"
            variant="square"
          >
            Company Logo
          </Avatar>
        )}
      </AuthConsumer>
    </Box>
  );
}

export default CompanyLogo;

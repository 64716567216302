import theme from "../Theme";
import { compareMilestones } from "./utils";

export interface Data {
  leadNumber: string;
  leadPeriod: string;
  leadDate: Date;
  leadApps: string;
  leadMembers: string;
  leadListings: string;
  repName: string;
  repNameWc: string;
  repPhone: string;
  repPhoneWc: string;
  repEmail: string;
  repEmailWc: string;
  companyId: number;
  compName: string;
  persFirstName: string;
  persLastName: string;
  persTitle: string;
  persEmailAddress: string;
  nextTouch: Date;
  lastTouch: Date;
  leadType: string;
  leadStatus: string;
  leadStatusWc: string;
  leadRetReason: string;
  accountId: string;
  acctDate: string;
  acctStatus: string;
  acctCost: string;
  acctOutlook: string;
  acctArchived: string;
  acctAmtPayable: string;
  acctFundDate: string;
  acctType: string;
  vendorName: string;
}

export const statusToStyle = (status: string): any => {
  if (!status) return theme.pipelines.statusStyles.default;
  const statusNameNormalized = status.split(" ").join("").replace("-", "");
  const color = theme.pipelines.statusStyles[statusNameNormalized];
  return color || theme.pipelines.statusStyles.default;
};

export const internalToDisplayStatus = (
  status: string,
  activityType: "lead" | "transaction"
): string => {
  switch (status) {
    case "Stalled":
      return activityType === "lead" ? "Passive Lead" : "Stalled";
    case "Active Lead":
    case "Application Out":
      return "New Lead";
    case "Application Submitted":
    case "Application In":
      return "Application Received";
    case "Pre-Qualified":
    case "Qualified Positive":
    case "New Transaction":
    case "Qualified":
      return "Pre-Qualified";
    case "Customer Action Required":
      return "Customer Action Required";
    case "Submitted":
    case "Underwriting":
    case "Needs Syndication":
      return "In Underwriting";
    case "Declined":
    case "Credit Disqualified":
      return "Credit Pending";
    case "Conditionally Approved":
    case "Approved":
    case "Ready for Documentation":
    case "Ready for Doc Request":
      return "Approved";
    case "Need Information":
      return "Needs Information";
    case "Documents Out":
      return "Contracts Sent";
    case "Documents In":
      return "Contracts Received";
    case "In for Funding":
    case "Funding":
      return "In for Funding";
    case "Booked":
    case "Paid Off":
    case "Residual Paid":
    case "Residual Due":
    case "In Renewals":
      return "Funded";
    default:
      return status;
  }
};

export const getFinanceManager = (rowData: Data) =>
  rowData.repName !== "house" ? rowData.repName : rowData.repNameWc;

export const getMilestones = (
  milestones: {
    [status: string]: string | null;
  },
  activityType: "lead" | "transaction"
): Array<any> => {
  const milestonesToDisplay = [
    "New Lead",
    "Application Received",
    "In Underwriting",
    "Pre-Qualified",
    "Approved",
    "In for Funding",
    "Funded",
    "Credit Pending",
    "Needs Syndication",
    "Stalled",
    "Passive Lead",
    "Needs Information",
    "Contracts Sent",
    "Contracts Received",
  ];
  const milestonesKeys = Object.keys(milestones);
  const milestonesList: Array<any> = [];
  milestonesKeys.forEach((key) => {
    const displayStatus = internalToDisplayStatus(key, activityType);
    const milestoneDate = milestones[key];
    if (milestonesToDisplay.indexOf(displayStatus) !== -1 && milestoneDate) {
      milestonesList.push({
        status: displayStatus,
        date: new Date(milestoneDate),
      });
    }
  });
  return milestonesList.sort(compareMilestones);
};

export const getCompanyIdDisplayValue = (data: any) =>
  data.leadNumber && data.leadNumber !== ""
    ? `(${data.leadNumber}) ${data.companyId}`
    : `${data.companyId}`;

export const getPartShareDisplayValue = (data: any) =>
  data.acctAmtPayable1 &&
  data.acctAmtPayable1 !== 0 &&
  data.acctAmtPayable2 &&
  data.acctAmtPayable2 !== 0
    ? `$${data.acctAmtPayable} ($${data.acctAmtPayable1}, $${data.acctAmtPayable2})`
    : data.acctAmtPayable
    ? `$${data.acctAmtPayable}`
    : `${data.acctAmtPayable}`;

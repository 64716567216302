import React from "react";
import { getVendors } from "../../Api";
import auth from "../../Services/auth";

interface WithDataProps {}
interface WithDataState {
  data: Array<any>;
  isLoading: boolean;
  isError: boolean;
  vendors: Array<any>;
  reload: boolean;
}

const withData = <P extends object>(
  InnerComponent: React.ComponentType<P>,
  getData: (s: (d: Array<any>) => void, e: (e: any) => void) => void
) =>
  class WithDat extends React.Component<P & WithDataProps, WithDataState> {
    constructor(props: P & WithDataProps) {
      super(props);
      this.state = {
        data: [],
        isLoading: true,
        isError: false,
        vendors: [],
        reload: false,
      };
    }

    loadData() {
      getData(
        (data) => {
          this.setState({
            data: data,
            isLoading: false,
            isError: false,
            reload: false,
          });
        },
        (err) => {
          this.setState({
            isError: true,
            isLoading: false,
            data: [],
            reload: false,
          });
        }
      );
    }

    componentDidMount() {
      var isAdmin = auth.isInRole("Admin");
      if (isAdmin) {
        getVendors(
          (data) => {
            this.setState({
              vendors: data,
            });
          },
          (err) => {
            this.setState({
              vendors: [],
            });
          }
        );
      }
      this.loadData();
    }

    componentDidUpdate() {
      if (this.state.reload) {
        this.loadData();
      }
    }

    render() {
      return (
        <InnerComponent
          data={this.state.data}
          isLoading={this.state.isLoading}
          vendors={this.state.vendors}
          onReload={() => this.setState({ reload: true, isLoading: true })}
          {...(this.props as P)}
        />
      );
    }
  };

export default withData;

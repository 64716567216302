const data = [
  {
    lead_number: "100",
    lead_date: "2020-03-23 17:10:07.796666",
    lead_period: "2020-03",
    lead_apps: null,
    lead_members: null,
    lead_listings: null,
    rep_name: "Mark TheGreat",
    rep_phone: "555-555-7726",
    rep_email: "someone@taycor.com",
    companyid: 1255859,
    comp_name: "Concrete inc",
    pers_firstname: "Random",
    pers_lastname: "LastName",
    pers_title: null,
    pers_emailaddress: "random@concrete",
    next_touch: "2020-03-24 09:00:00",
    last_touch: "2020-03-23 17:16:02.683333",
    lead_type: "Buyer",
    lead_status: "Active Lead"
  }
];

export default data;

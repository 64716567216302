import config from "../config";
import { checkForUserPVD } from "../Helpers/hooks/checkForUserPVD";
import { ActivityType, ParentVendorDealer } from "../Helpers/types";
import fakeDataAll from "./fakes/all.fake";
import {
  del,
  get,
  patchWithFiles,
  post,
  postWithFiles,
} from "./fetch-portal-api";

const postLogin = (
  data: any,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post("/accounts/login", data, success, error);
};

const postRefreshToken = (
  refreshToken: any,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post(
    "/accounts/refreshtoken",
    { refreshToken: refreshToken },
    success,
    error
  );
};

const postLogout = (
  refreshToken: any,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post("/accounts/logout", { refreshToken: refreshToken }, success, error);
};

const getAll = (success: (data: any) => void, error: (err: any) => void) => {
  const emptyObject: ParentVendorDealer[] = [];

  post(
    `/dealpipelines/leads/all`,
    checkForUserPVD() || config.pvd.enabled
      ? { Vendors: "", LeadSourceIds: getVendorsParameter() }
      : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
    success,
    error,
    fakeDataAll
  );
};

const getActiveLeads = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  const emptyObject: ParentVendorDealer[] = [];
  post(
    `/dealpipelines/leads/active`,
    checkForUserPVD() || config.pvd.enabled
      ? { Vendors: "", LeadSourceIds: getVendorsParameter() }
      : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
    success,
    error,
    fakeDataAll
  );
};

const getStalledLeads = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  const emptyObject: ParentVendorDealer[] = [];
  post(
    `/dealpipelines/leads/stalled`,
    checkForUserPVD() || config.pvd.enabled
      ? { Vendors: "", LeadSourceIds: getVendorsParameter() }
      : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
    success,
    error,
    fakeDataAll
  );
};

const getActiveTransactions = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  const emptyObject: ParentVendorDealer[] = [];
  post(
    "/dealpipelines/transactions/active",
    checkForUserPVD() || config.pvd.enabled
      ? { Vendors: "", LeadSourceIds: getVendorsParameter() }
      : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
    success,
    error,
    fakeDataAll
  );
};

const getStalledTransactions = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  const emptyObject: ParentVendorDealer[] = [];

  post(
    `/dealpipelines/transactions/stalled`,
    checkForUserPVD() || config.pvd.enabled
      ? { Vendors: "", LeadSourceIds: getVendorsParameter() }
      : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
    success,
    error,
    fakeDataAll
  );
};

const getFundedTransactions = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  const emptyObject: ParentVendorDealer[] = [];

  post(
    `/dealpipelines/transactions/funded`,
    checkForUserPVD() || config.pvd.enabled
      ? { Vendors: "", LeadSourceIds: getVendorsParameter() }
      : { Vendors: getVendorsParameter(), LeadSourceIds: emptyObject },
    success,
    error,
    fakeDataAll
  );
};

const getUsers = (success: (data: any) => void, error: (err: any) => void) => {
  get("/accounts", success, error);
};

const getBrandUsers = (
  brand: string,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(`/accounts/${brand}`, success, error);
};

const postAddUser = (
  data: any,
  file: any,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  postWithFiles("/accounts", data, { companyLogoFile: file }, success, error);
};

const patchUpdateUser = (
  data: any,
  file: any,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  patchWithFiles("/accounts", data, { companyLogoFile: file }, success, error);
};

const postResetPassword = (
  data: any,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post("/accounts/resetpassword", data, success, error);
};

const postForgotPassword = (
  emailAddress: string,
  brand: string,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post("/accounts/forgotpassword", { emailAddress, brand }, success, error);
};

const getChatHash = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get("/accounts/chathash", success, error);
};

const getBrands = (success: (data: any) => void, error: (err: any) => void) => {
  get("/brands", success, error);
};

const getBrandIds = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get("/brandids", success, error);
};

const getVendors = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get("/vendors", success, error);
};

const getParentVendorDealer = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get("/parentvendordealer", success, error);
};

const getParentVendorDealerWithBrand = (
  brandIds: string[],
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post(
    `/parentvendordealer/pidsandvids`,
    { BrandIds: brandIds },
    success,
    error,
    fakeDataAll
  );
};

const getDidsAndRidsWithBrand = (
  brandIds: string[],
  PidsAndVids: string[],
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post(
    `/parentvendordealer/didsandrids`,
    { BrandIds: brandIds, LeadSourceIds: PidsAndVids },
    success,
    error,
    fakeDataAll
  );
};
const getRidsWithBrand = (
  brandIds: string[],
  dids: string[],
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post(
    `/parentvendordealer/rids`,
    { BrandIds: brandIds, LeadSourceIds: dids },
    success,
    error,
    fakeDataAll
  );
};

const getCompanyAndTaycorRep = (
  activityId: string,
  activityType: ActivityType,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(`/${activityType}s/${activityId}/companyandrep`, success, error);
};

const getMilestones = (
  activityId: string,
  activityType: ActivityType,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(`/${activityType}s/${activityId}/milestones`, success, error);
};

const getMyPipeline = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(`/dashboards/mypipeline`, success, error);
};

const getActiveInventory = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(
    `/inventorytracker/active?vendors=${getVendorsParameter()}`,
    success,
    error,
    fakeDataAll
  );
};

const getSoldInventory = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(
    `/inventorytracker/sold?vendors=${getVendorsParameter()}`,
    success,
    error,
    fakeDataAll
  );
};

const getAllInventory = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(
    `/inventorytracker/all?vendors=${getVendorsParameter()}`,
    success,
    error,
    fakeDataAll
  );
};

const getInventoryItemDetails = (
  itemId: string,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(`/inventorytracker/${itemId}/details`, success, error);
};

const getMyInventory = (
  success: (data: any) => void,
  error: (err: any) => void
) => {
  get(`/dashboards/myinventory`, success, error);
};

const postCreateUploadInstance = (
  itemId: string,
  itemType: "lead" | "transaction",
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post(`/uploads/${itemType}/${itemId}`, {}, success, error);
};

const deleteUploadInstance = (
  itemId: string,
  itemType: "lead" | "transaction",
  success: (data: any) => void,
  error: (err: any) => void
) => {
  del(`/uploads/${itemType}/${itemId}`, success, error);
};

const postSetupApiAccess = (
  userId: string,
  success: (data: any) => void,
  error: (err: any) => void
) => {
  post(`/accounts/${userId}/apiaccess`, {}, success, error);
};

const getVendorsParameter = () => {
  if (checkForUserPVD() || config.pvd.enabled) {
    const incomingPVDs: ParentVendorDealer[] = JSON.parse(
      sessionStorage.getItem("vendors") || "[]"
    );
    return incomingPVDs.map((p) => p.id);
  }
  return encodeURIComponent(
    JSON.parse(sessionStorage.getItem("vendors") || "[]").join(",")
  ).substr(0, 1500);
};

export {
  postLogin,
  postRefreshToken,
  postLogout,
  getAll,
  getActiveLeads,
  getStalledLeads,
  getActiveTransactions,
  getStalledTransactions,
  getFundedTransactions,
  getUsers,
  postAddUser,
  patchUpdateUser,
  postResetPassword,
  postForgotPassword,
  getChatHash,
  getVendors,
  getParentVendorDealer,
  getParentVendorDealerWithBrand,
  getDidsAndRidsWithBrand,
  getBrands,
  getCompanyAndTaycorRep,
  getMilestones,
  getMyPipeline,
  getActiveInventory,
  getSoldInventory,
  getInventoryItemDetails,
  getMyInventory,
  getAllInventory,
  postCreateUploadInstance,
  deleteUploadInstance,
  postSetupApiAccess,
  getBrandUsers,
  getBrandIds,
  getRidsWithBrand,
};

import { Box, Chip, Divider, Popover, TextField } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import { Button, Plus } from "@taycor/ui-library";
import React, { useCallback, useEffect, useState } from "react";
import { getParentVendorDealer } from "../../../../Api/portal-api";
import { ParentVendorDealer } from "../../../../Helpers/types";
import PvdsAutocomplete from "../../../Common/PvdsAutocomplete";
import useStyles from "./leadSourceFilter.style";

interface MenuOptionsProps {
  pvdIds: ParentVendorDealer[];
  type: string;
  handleSelectedPVDIds: any;
  selectedPVDIdsFromChips: any;
}

const MenuOption = ({
  pvdIds,
  type,
  handleSelectedPVDIds,
  selectedPVDIdsFromChips,
}: MenuOptionsProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPvdIds, setSelectedPVDIds] = useState<ParentVendorDealer[]>(
    []
  );

  const text = () => {
    switch (type) {
      case "pids":
        return "Parent ID (PID)";
      case "vids":
        return "Vendor ID (VID)";
      case "dids":
        return "Dealer ID (DID)";
      case "rids":
        return "Representative ID (RID)";
    }
  };

  useEffect(() => {
    setSelectedPVDIds(selectedPVDIdsFromChips);
  }, [selectedPVDIdsFromChips]);

  const handleSelection = (selected: ParentVendorDealer[]) => {
    setOpen(false);
    setSelectedPVDIds(selected);
    handleSelectedPVDIds(selected, type);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <TextField
        multiline
        value={text()}
        variant="standard"
        className={open ? classes.pvdOpen : classes.pvdNotOpen}
        onClick={(e: any) => {
          setOpen(!open);
          setAnchorEl(e.currentTarget);
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <>
              {open ? (
                <ArrowBackIos className={classes.arrowAdornment}></ArrowBackIos>
              ) : (
                <ArrowForwardIos
                  className={classes.arrowAdornment}
                ></ArrowForwardIos>
              )}
            </>
          ),
        }}
      ></TextField>
      <PvdsAutocomplete
        pvdIdsArray={pvdIds}
        openInitital={open}
        anchorEl={anchorEl}
        handleSelectedPVDIds={handleSelection}
        filtering={true}
        chipping={false}
        selectedCome={selectedPvdIds}
        handleClose={handleClose}
        type={type}
      ></PvdsAutocomplete>
    </>
  );
};

interface FilterChipProps {
  selectedPVDIds: ParentVendorDealer[];
  type: string;
  handleChipOpen: any;
  handleChipDelete: any;
}

const FilterChip = ({
  selectedPVDIds,
  type,
  handleChipOpen,
  handleChipDelete,
}: FilterChipProps) => {
  const getName = () => {
    switch (type) {
      case "pids":
        return "Parent";
      case "vids":
        return "Vendor";
      case "dids":
        return "Dealer";
      case "rids":
        return "Representative";
    }
  };

  return (
    <Chip
      style={{ backgroundColor: "#EFF1F5", borderRadius: 1, border: 0 }}
      onClick={(e: any) => {
        handleChipOpen(e.currentTarget, type);
      }}
      onDelete={() => {
        handleChipDelete(type);
      }}
      variant="outlined"
      label={`${getName()} IDs: ${selectedPVDIds[0].name}, ${
        selectedPVDIds.length === 1 ? "" : ` +${selectedPVDIds.length - 1}`
      }`}
      deleteIcon={<Close style={{ width: 30, height: 15, color: "#14679F" }} />}
    ></Chip>
  );
};

interface LeadSourceFilterProps {
  handlePVDIds: any;
}
const LeadSourceFilter = ({ handlePVDIds }: LeadSourceFilterProps) => {
  const classes = useStyles();

  const [pids, setPids] = useState<ParentVendorDealer[]>([]);
  const [vids, setVids] = useState<ParentVendorDealer[]>([]);
  const [dids, setDids] = useState<ParentVendorDealer[]>([]);
  const [rids, setRids] = useState<ParentVendorDealer[]>([]);

  const [selectedPids, setSelectedPids] = useState<ParentVendorDealer[]>([]);
  const [selectedVids, setSelectedVids] = useState<ParentVendorDealer[]>([]);
  const [selectedDids, setSelectedDids] = useState<ParentVendorDealer[]>([]);
  const [selectedRids, setSelectedRids] = useState<ParentVendorDealer[]>([]);

  const [mainOpen, setMainOpen] = useState<boolean>(false);

  const [pidsChipOpen, setPidsChipOpen] = useState<boolean>(false);
  const [vidsChipOpen, setVidsChipOpen] = useState<boolean>(false);
  const [didsChipOpen, setDidsChipOpen] = useState<boolean>(false);
  const [ridsChipOpen, setRidsChipOpen] = useState<boolean>(false);

  const [mainAnchor, setMainAnchor] = useState(null);

  const [pidsAnchor, setPidsAnchor] = useState(null);
  const [vidsAnchor, setVidsAnchor] = useState(null);
  const [didsAnchor, setDidsAnchor] = useState(null);
  const [ridsAnchor, setRidsAnchor] = useState(null);

  useEffect(() => {
    const ids = selectedPids.concat(selectedVids, selectedDids, selectedRids);
    handlePVDIds([...ids]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDids, selectedPids, selectedRids, selectedVids]);

  useEffect(() => {
    const defaultVendors = JSON.parse(
      sessionStorage.getItem("vendors") || "[]"
    );
    if (defaultVendors.length > 0) {
      const selectedPidsSession: ParentVendorDealer[] = [];
      const selectedVidsSession: ParentVendorDealer[] = [];
      const selectedDidsSession: ParentVendorDealer[] = [];
      const selectedRidsSession: ParentVendorDealer[] = [];

      defaultVendors.forEach((element: ParentVendorDealer) => {
        if (element.type === 0) {
          selectedPidsSession.push(element);
        }
        if (element.type === 1) {
          selectedVidsSession.push(element);
        }
        if (element.type === 2) {
          selectedDidsSession.push(element);
        }
        if (element.type === 3) {
          selectedRidsSession.push(element);
        }
      });
      setSelectedPids(selectedPidsSession);
      setSelectedVids(selectedVidsSession);
      setSelectedDids(selectedDidsSession);
      setSelectedRids(selectedRidsSession);
    }
  }, []);

  const loadPids = useCallback(async () => {
    getParentVendorDealer(
      (data) => {
        const pidsArray: ParentVendorDealer[] = [];
        const vidsArray: ParentVendorDealer[] = [];
        const didsArray: ParentVendorDealer[] = [];
        const ridsArray: ParentVendorDealer[] = [];

        data.forEach((element: ParentVendorDealer) => {
          if (element.type === 0) pidsArray.push(element);
          if (element.type === 1) vidsArray.push(element);
          if (element.type === 2) didsArray.push(element);
          if (element.type === 3) ridsArray.push(element);
        });

        setPids(pidsArray);
        setVids(vidsArray);
        setDids(didsArray);
        setRids(ridsArray);
      },
      (err) => {}
    );
  }, []);
  useEffect(() => {
    loadPids();
  }, [loadPids]);

  const handleSelectedPids = (selected: ParentVendorDealer[]) => {
    setMainOpen(false);
    setSelectedPids(selected);
    setPidsChipOpen(false);
  };
  const handleSelectedVids = (selected: ParentVendorDealer[]) => {
    setMainOpen(false);
    setSelectedVids(selected);
    setVidsChipOpen(false);
  };
  const handleSelectedDids = (selected: ParentVendorDealer[]) => {
    setMainOpen(false);
    setSelectedDids(selected);
    setDidsChipOpen(false);
  };
  const handleSelectedRids = (selected: ParentVendorDealer[]) => {
    setMainOpen(false);
    setSelectedRids(selected);
    setRidsChipOpen(false);
  };

  const handlePidsChipClose = () => {
    setPidsChipOpen(false);
  };
  const handleVidsChipClose = () => {
    setVidsChipOpen(false);
  };
  const handleDidsChipClose = () => {
    setDidsChipOpen(false);
  };
  const handleRidsChipClose = () => {
    setRidsChipOpen(false);
  };

  const handleSelectedPVDIds = (
    selected: ParentVendorDealer[],
    type: String
  ) => {
    switch (type) {
      case "pids":
        setSelectedPids(selected);
        setMainOpen(false);
        setPidsChipOpen(false);
        break;
      case "vids":
        setSelectedVids(selected);
        setMainOpen(false);
        setVidsChipOpen(false);
        break;
      case "dids":
        setSelectedDids(selected);
        setMainOpen(false);
        setDidsChipOpen(false);
        break;
      case "rids":
        setSelectedRids(selected);
        setMainOpen(false);
        setRidsChipOpen(false);
        break;
    }
  };

  const handleChipOpen = (e: any, type: string) => {
    switch (type) {
      case "pids":
        setPidsAnchor(e);
        setPidsChipOpen(true);
        break;
      case "vids":
        setVidsAnchor(e);
        setVidsChipOpen(true);
        break;
      case "dids":
        setDidsAnchor(e);
        setDidsChipOpen(true);
        break;
      case "rids":
        setRidsAnchor(e);
        setRidsChipOpen(true);
        break;
    }
  };

  const handleChipDelete = (type: string) => {
    switch (type) {
      case "pids":
        setSelectedPids([]);
        window.location.reload();
        break;
      case "vids":
        setSelectedVids([]);
        window.location.reload();

        break;
      case "dids":
        setSelectedDids([]);
        window.location.reload();

        break;
      case "rids":
        setSelectedRids([]);
        window.location.reload();

        break;
    }
  };

  return (
    <>
      {" "}
      <Box className={classes.pvdChipBox}>
        {selectedPids.length > 0 && (
          <FilterChip
            selectedPVDIds={selectedPids}
            type={"pids"}
            handleChipOpen={handleChipOpen}
            handleChipDelete={handleChipDelete}
          ></FilterChip>
        )}
        {selectedVids.length > 0 && (
          <FilterChip
            selectedPVDIds={selectedVids}
            type={"vids"}
            handleChipOpen={handleChipOpen}
            handleChipDelete={handleChipDelete}
          ></FilterChip>
        )}
        {selectedDids.length > 0 && (
          <FilterChip
            selectedPVDIds={selectedDids}
            type={"dids"}
            handleChipOpen={handleChipOpen}
            handleChipDelete={handleChipDelete}
          ></FilterChip>
        )}
        {selectedRids.length > 0 && (
          <FilterChip
            selectedPVDIds={selectedRids}
            type={"rids"}
            handleChipOpen={handleChipOpen}
            handleChipDelete={handleChipDelete}
          ></FilterChip>
        )}
      </Box>
      <PvdsAutocomplete
        pvdIdsArray={pids}
        openInitital={pidsChipOpen}
        anchorEl={pidsAnchor}
        handleSelectedPVDIds={handleSelectedPids}
        filtering={false}
        chipping={true}
        selectedCome={selectedPids}
        handleClose={handlePidsChipClose}
      ></PvdsAutocomplete>
      <PvdsAutocomplete
        pvdIdsArray={vids}
        openInitital={vidsChipOpen}
        anchorEl={vidsAnchor}
        handleSelectedPVDIds={handleSelectedVids}
        filtering={false}
        chipping={true}
        selectedCome={selectedVids}
        handleClose={handleVidsChipClose}
      ></PvdsAutocomplete>
      <PvdsAutocomplete
        pvdIdsArray={dids}
        openInitital={didsChipOpen}
        anchorEl={didsAnchor}
        handleSelectedPVDIds={handleSelectedDids}
        filtering={false}
        chipping={true}
        selectedCome={selectedDids}
        handleClose={handleDidsChipClose}
      ></PvdsAutocomplete>
      <PvdsAutocomplete
        pvdIdsArray={rids}
        openInitital={ridsChipOpen}
        anchorEl={ridsAnchor}
        handleSelectedPVDIds={handleSelectedRids}
        filtering={false}
        chipping={true}
        selectedCome={selectedRids}
        handleClose={handleRidsChipClose}
      ></PvdsAutocomplete>
      <Box className={classes.pvdActionsBox}>
        <Button
          icon={<Plus></Plus>}
          classes={{ root: classes.pvdActionsButtons }}
          variant="text"
          label="Add filter"
          action={(e: any) => {
            setMainOpen(true);
            setMainAnchor(e.currentTarget);
          }}
        ></Button>
        {(selectedPids.length > 0 ||
          selectedDids.length > 0 ||
          selectedRids.length > 0 ||
          selectedVids.length > 0) && (
          <>
            <Divider orientation="vertical" />
            <Button
              classes={{ root: classes.pvdActionsButtons }}
              variant="text"
              label="Clear"
              action={(e: any) => {
                setSelectedPids([]);
                setSelectedDids([]);
                setSelectedVids([]);
                setSelectedRids([]);
              }}
            ></Button>
          </>
        )}
      </Box>
      <Popover
        onBackdropClick={() => setMainOpen(false)}
        classes={{ paper: classes.pvdMainPopover }}
        open={mainOpen}
        anchorEl={mainAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <MenuOption
          pvdIds={pids}
          type={"pids"}
          handleSelectedPVDIds={handleSelectedPVDIds}
          selectedPVDIdsFromChips={selectedPids}
        ></MenuOption>
        <MenuOption
          pvdIds={vids}
          type={"vids"}
          handleSelectedPVDIds={handleSelectedPVDIds}
          selectedPVDIdsFromChips={selectedVids}
        ></MenuOption>
        <MenuOption
          pvdIds={dids}
          type={"dids"}
          handleSelectedPVDIds={handleSelectedPVDIds}
          selectedPVDIdsFromChips={selectedDids}
        ></MenuOption>
        <MenuOption
          pvdIds={rids}
          type={"rids"}
          handleSelectedPVDIds={handleSelectedPVDIds}
          selectedPVDIdsFromChips={selectedRids}
        ></MenuOption>
      </Popover>
    </>
  );
};

export default LeadSourceFilter;

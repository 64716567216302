import { SvgIcon } from "@material-ui/core";
import React from "react";

export default function CopyIcon() {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0C0.671573 0 0 0.671573 0 1.5V8.5C0 9.32843 0.671573 10 1.5 10H2V10.5C2 11.3284 2.67157 12 3.5 12H10.5C11.3284 12 12 11.3284 12 10.5V3.5C12 2.67157 11.3284 2 10.5 2H10V1.5C10 0.671573 9.32843 0 8.5 0H1.5ZM10 3V8.5C10 9.32843 9.32843 10 8.5 10H3V10.5C3 10.7761 3.22386 11 3.5 11H10.5C10.7761 11 11 10.7761 11 10.5V3.5C11 3.22386 10.7761 3 10.5 3H10ZM1 1.5C1 1.22386 1.22386 1 1.5 1H8.5C8.77614 1 9 1.22386 9 1.5V8.5C9 8.77614 8.77614 9 8.5 9H1.5C1.22386 9 1 8.77614 1 8.5V1.5Z"
        fill="#383939"
      />
    </SvgIcon>
  );
}

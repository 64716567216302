import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { postForgotPassword } from "../../Api";
import { useBrandedPortal } from "../../Helpers/hooks/useBrandedPortal";
import useStyles from "./forgotPassword.style";

interface Values {
  emailAddress: string;
}
interface ForgotPasswordProps {
  loginUrl?: string;
}

const ForgotPassword = (props: ForgotPasswordProps) => {
  const classes = useStyles();
  const loginUrl = props.loginUrl ? props.loginUrl : "/login";

  const [showPwdResetMessage, setshowPwdResetMessage] =
    useState<boolean>(false);

  const brandedPortal = useBrandedPortal();
  const isBrandedPortal = brandedPortal.isBrandedPortal;

  return (
    <Box className={classes.root}>
      {!showPwdResetMessage && (
        <>
          <Typography variant="h1" className={classes.forgotPasswordText}>
            Forgot your password?
          </Typography>
          <Typography
            variant="h6"
            className={classes.forgotPasswordDetailsText}
          >
            We'll email your instruction on how to reset it.
          </Typography>
          <Formik
            initialValues={{
              emailAddress: "",
            }}
            validate={(values) => {
              const errors: Partial<Values> = {};
              if (!values.emailAddress) {
                errors.emailAddress = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              postForgotPassword(
                values.emailAddress,
                isBrandedPortal ? brandedPortal.brand : "Taycor",
                () => {
                  setSubmitting(false);
                  setshowPwdResetMessage(true);
                },
                () => {
                  setSubmitting(false);
                  setshowPwdResetMessage(true);
                }
              );
            }}
          >
            {({ isSubmitting }) => (
              <Form className={classes.form}>
                <Field
                  component={TextField}
                  name="emailAddress"
                  type="text"
                  label="Email"
                  variant="standard"
                  size="medium"
                  margin="normal"
                  className={classes.formField}
                ></Field>
                {isSubmitting && <LinearProgress />}
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  className={classes.button}
                >
                  Reset Password
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
      {showPwdResetMessage && (
        <>
          <Typography variant="h1" className={classes.forgotPasswordText}>
            Reset Password
          </Typography>
          <Typography variant="h6" className={classes.pwdResetInstructions}>
            If we find a user account with that email address, you will receive
            and email from us shortly.
          </Typography>
          <Typography variant="h6" className={classes.pwdResetInstructions}>
            If you do not receive the email within a few minutes, please check
            your junk/spam email folder or call your relantionship manager or
            call (800) 322-9738.
          </Typography>
        </>
      )}

      <Divider variant="fullWidth" className={classes.divider}></Divider>
      <Typography className={classes.helpText} variant="h6">
        If you already have a user -{" "}
        <Link to={loginUrl} className={classes.loginLink}>
          Log In Here
        </Link>
      </Typography>
    </Box>
  );
};

export default ForgotPassword;

import React, { forwardRef } from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import EditIcon from "@material-ui/icons/Edit";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import SearchIcon from "@material-ui/icons/Search";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import RemoveIcon from "@material-ui/icons/Remove";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import useStyles, { tableStyle } from "./userList.style";
import theme from "../../../Theme";
import { searchFieldStyle } from "../../DealPipeline/common/baseView.style";

interface UserListProps {
  data: Array<any>;
  isLoading: boolean;
  isError: boolean;
  onEditUser: (user: any) => void;
}

const UserList = (props: UserListProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <MaterialTable
        title={
          <>
            <Typography variant="h1">Assigned Portal Users</Typography>
            <Typography variant="h6" className={classes.tableSubtitle}>
              Here you can manage users associated with your Partner Portal or
              edit information belonging to an existing user. If you need any
              assistance you can reach out to us via chat by using the chat icon
              in the lower right corner of your screen. If you need to add a
              user, you can do so by click on the “Add User” link at the bottom
              of the left sidebar. Please note that Company logos are limited to
              PNG or jpeg file types.
            </Typography>
            <Box className={classes.tableIcon}>
              <Box>
                <PersonIcon></PersonIcon>
              </Box>
            </Box>
          </>
        }
        columns={[
          {
            title: "Name",
            render: (rowData: any) => {
              return (
                <>
                  <Typography variant="h6" className={classes.companyName}>
                    {rowData.companyName}
                  </Typography>
                  <Typography variant="subtitle1">
                    {rowData.emailAddress}
                  </Typography>
                </>
              );
            },
            field: "companyName",
            width: 300,
          },
          { field: "emailAddress", hidden: true, searchable: true, width: 1 },
          {
            title: "Type",
            field: "role",
            render: (rowData: any) => (
              <Typography variant="h6">{rowData.role}</Typography>
            ),
            width: 90,
          },
          {
            title: "Last Login",
            field: "lastLogin",
            render: (rowData) => (
              <Typography variant="subtitle1">
                {rowData.hasPassword
                  ? rowData.lastLogin
                    ? new Date(rowData.lastLogin + "Z").toLocaleString()
                    : "No logins yet"
                  : "Password not set"}
              </Typography>
            ),
            customFilterAndSearch: (filter: any, rowData: any) => {
              const value = rowData.hasPassword
                ? rowData.lastLogin
                  ? new Date(rowData.lastLogin + "Z").toLocaleString()
                  : "No logins yet"
                : "Password not set";
              return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
            },
            width: 150,
          },
          { field: "companyLogo", title: "Logo", type: "boolean", width: 80 },
          { field: "isActive", title: "Active", type: "boolean", width: 80 },
          {
            title: "",
            render: (rowData: any) => {
              return (
                <Link
                  to="/admin/edit-user"
                  onClick={() => {
                    props.onEditUser(rowData);
                  }}
                  className={classes.actionLink}
                >
                  <Tooltip title="Edit User">
                    <EditIcon></EditIcon>
                  </Tooltip>
                </Link>
              );
            },
            sorting: false,
            searchable: false,
            type: "numeric",
            width: 50,
          },
        ]}
        data={props.data}
        isLoading={props.isLoading}
        options={{
          padding: "dense",
          tableLayout: "fixed",
          pageSize: 20,
          paginationType: "stepped",
          showFirstLastPageButtons: false,
          pageSizeOptions: [20],
          emptyRowsWhenPaging: false,
          searchFieldVariant: "outlined",
          searchFieldStyle: searchFieldStyle(theme),
        }}
        localization={{
          header: {
            actions: "",
          },
        }}
        style={tableStyle}
        icons={{
          Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
          Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} />),
          Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} />),
          Delete: forwardRef((props, ref) => (
            <DeleteOutlineIcon {...props} ref={ref} />
          )),
          DetailPanel: forwardRef((props, ref) => (
            <ChevronRightIcon {...props} ref={ref} />
          )),
          Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
          Export: forwardRef((props, ref) => (
            <SaveAltIcon {...props} ref={ref} />
          )),
          Filter: forwardRef((props, ref) => (
            <FilterListIcon {...props} ref={ref} />
          )),
          FirstPage: forwardRef((props, ref) => (
            <FirstPageIcon {...props} ref={ref} />
          )),
          LastPage: forwardRef((props, ref) => (
            <LastPageIcon {...props} ref={ref} />
          )),
          NextPage: forwardRef((props, ref) => (
            <ChevronRightIcon {...props} ref={ref} />
          )),
          PreviousPage: forwardRef((props, ref) => (
            <ChevronLeftIcon {...props} ref={ref} />
          )),
          ResetSearch: forwardRef((props, ref) => (
            <ClearIcon {...props} ref={ref} />
          )),
          Search: forwardRef((props, ref) => (
            <SearchIcon {...props} ref={ref} />
          )),
          SortArrow: forwardRef((props, ref) => (
            <ArrowUpwardIcon {...props} ref={ref} />
          )),
          ThirdStateCheck: forwardRef((props, ref) => (
            <RemoveIcon {...props} ref={ref} />
          )),
          ViewColumn: forwardRef((props, ref) => (
            <ViewColumnIcon {...props} ref={ref} />
          )),
        }}
      ></MaterialTable>
    </Box>
  );
};

export default UserList;

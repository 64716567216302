import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    title: {
      textAlign: "center",
      marginTop: theme.spacing(8),
    },
    subtitle: {
      textAlign: "center",
      marginTop: theme.spacing(4),
    },
    documents: {
      textAlign: "center",
      fontWeight: "bold",
      marginTop: theme.spacing(4),
    },
    document: {
      width: "80%",
      height: 44,
      borderRadius: 22,
      backgroundColor: "#9efdff",
      display: "flex",
      justifyContent: "start",
      marginTop: theme.spacing(3),
      "& .MuiChip-label": {
        width: "100%",
        textAlign: "center",
        color: "#4e4e4e",
        fontWeight: 300,
      },
      "& svg": {
        marginLeft: 13,
        color: "#79c8c9",
      },
    },
    button: {
      width: 370,
      marginTop: theme.spacing(8),
      color: theme.palette.common.white,
      textTransform: "capitalize",
      [theme.breakpoints.down(360)]: {
        width: "100%",
      },
    },
  })
);

export default useStyles;

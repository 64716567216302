import React from "react";
import { Tooltip, Avatar, Box, Typography } from "@material-ui/core";
import { avatarStyle, avatarTextStyle, chipStyle } from "./columns.style";

const renderChip = (
  value: string,
  valueToStyle: (value: string) => any,
  getDisplayStatus: (status: string) => string
) => {
  const displayStatus = getDisplayStatus(value);
  return (
    <Box style={chipStyle(valueToStyle(displayStatus))}>
      <Typography variant="body2">{displayStatus}</Typography>
    </Box>
  );
};

const renderAvatar = (value: string) => (
  <Box style={avatarStyle}>
    <Tooltip title={value}>
      <Avatar>{value ? value.replace(/[^A-Z{2}]/g, "") : "N/A"}</Avatar>
    </Tooltip>
    <Typography variant="body1" style={avatarTextStyle}>
      {value ? value.substring(0, value.indexOf(" ")) : "N/A"}
    </Typography>
  </Box>
);

export { renderChip, renderAvatar };

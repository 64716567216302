import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { formField } from "../../Theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down(865)]: {
        width: "100vw",
        textAlign: "center",
      },
    },
    partnerPortalText: {
      marginLeft: 15,
      [theme.breakpoints.down(865)]: {
        marginTop: theme.spacing(8),
      },
    },
    loginText: {
      marginTop: 7,
      marginLeft: 15,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      marginTop: 50,
      [theme.breakpoints.down(502)]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },
    formField: {
      ...formField,
      width: 502,
      [theme.breakpoints.down(502)]: {
        width: "100%",
      },
    },
    loginInButton: {
      width: 338,
      height: 45,
      marginTop: theme.spacing(16),
      textTransform: "capitalize",
      color: theme.palette.common.white,
      [theme.breakpoints.down(502)]: {
        width: "90vw",
      },
    },
    loginInButtonMidpoint: {
      width: 338,
      height: 45,
      marginTop: theme.spacing(16),
      textTransform: "capitalize",
      color: "#6f4d9e",
      [theme.breakpoints.down(502)]: {
        width: "90vw",
      },
    },
    divider: {
      marginTop: 53,
      backgroundColor: theme.colors.lightGrey,
      ...defaultWidth(theme),
    },
    forgotYourPassoword: {
      textAlign: "center",
      marginTop: 10,
      "& a": {
        textDecoration: "none",
        color: theme.palette.text.secondary,
      },
      ...defaultWidth(theme),
    },
    helpText: {
      color: theme.palette.text.secondary,
      marginTop: 53,
      ...defaultWidth(theme),
    },
    alert: {
      marginTop: 53,
      textAlign: "center",
      ...defaultWidth(theme),
    },
    visible: {
      display: "block",
    },
    hidden: {
      display: "none",
    },
  })
);

const defaultWidth = (theme: Theme) => ({
  width: 502,
  [theme.breakpoints.down(865)]: {
    width: "100vw",
  },
});

export default useStyles;

import React from "react";
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from "@material-ui/core";
import classnames from "classnames";
import useStyles from "./company.style";
import { internalToDisplayStatus } from "../../../../Helpers/inventory-tracker";

interface EquipmentInfo {
  label: string;
  value: string;
}

interface EquipmentDetailsProps {
  item: any;
}

const EquipmentDetails = (props: EquipmentDetailsProps) => {
  const classes = useStyles();
  const { item } = props;

  const generalData: Array<EquipmentInfo> = [
    {
      label: "ID",
      value: item.id,
    },
    { label: "Status", value: internalToDisplayStatus(item.status) },
    {
      label: "Unit Price",
      value: Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(item.unitPrice || 0),
    },
    { label: "GPS ID", value: item.gpsId },
    { label: "UCC Filling ID", value: item.uccFillingId },
  ];

  const renderDataTable = (
    data: Array<EquipmentInfo>,
    title?: string | null,
    subtitle?: string | null,
    titleSpacing?: "small" | "medium" | "large",
    subtitleSize?: "small" | "default"
  ) => (
    <TableContainer
      component={Paper}
      className={classes.table}
      key={title || subtitle || "key"}
    >
      <Table>
        <TableHead>
          {title && (
            <TableRow
              className={classnames(classes.tableTitleRow, {
                [classes.tableTitleRowSpacingSm]: titleSpacing === "small",
                [classes.tableTitleRowSpacingMd]: titleSpacing === "medium",
                [classes.tableTitleRowSpacingLg]: titleSpacing === "large",
              })}
            >
              <TableCell colSpan={2} align="center">
                <Typography variant="h4">{title}</Typography>
              </TableCell>
            </TableRow>
          )}
          {subtitle && (
            <TableRow className={classes.tableSubtitleRow}>
              <TableCell colSpan={2} align="center">
                <Typography variant={subtitleSize === "small" ? "h6" : "h5"}>
                  {subtitle}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="right" className={classes.tableLabelCell}>
                <Typography variant="h6" className={classes.tableLabelCellText}>
                  {item.label}:
                </Typography>
              </TableCell>
              <TableCell className={classes.tableValueCell}>
                <Typography variant="h6">{item.value}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {renderDataTable(
        generalData,
        item.modelNumber,
        item.serialNumber,
        "small",
        "small"
      )}
    </>
  );
};

export default EquipmentDetails;

import React from "react";
import {
  Box,
  Typography,
  Grid,
  withStyles,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import classnames from "classnames";
import { getMyPipeline } from "../../Api";
import { numberToShortFormat } from "../../Helpers/formatters";
import { Link } from "react-router-dom";
import styles from "./myPipeline.style";
import { CompanyLogo } from "../Common";

interface Tile {
  title: string;
  value: string;
  image: string;
  cssClass: string;
  index?: number;
  url?: string;
}

interface MyPipelineProps {
  menuExpanded: boolean;
  classes?: any;
  baseUrl?: string;
  isIntegrationPlatform?: boolean;
  setTab?: Function;
}

interface MyPipelineData {
  totalLeads: number;
  activeLeads: number;
  activeTransactions: number;
  fundedTransactions: number;
  last30DaysLeads: number;
  yearToDateLeads: number;
  averageTransactionSize: number;
  fundedVolume: number;
}
interface MyPipelineState {
  data: MyPipelineData;
  isLoading: boolean;
  isError: boolean;
}

class MyPipeline extends React.Component<MyPipelineProps, MyPipelineState> {
  constructor(props: MyPipelineProps) {
    super(props);
    this.state = {
      data: {
        totalLeads: 0,
        activeLeads: 0,
        activeTransactions: 0,
        fundedTransactions: 0,
        last30DaysLeads: 0,
        yearToDateLeads: 0,
        averageTransactionSize: 0,
        fundedVolume: 0,
      },
      isLoading: false,
      isError: false,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    getMyPipeline(
      (data: MyPipelineData) => {
        this.setState({ data: data, isLoading: false, isError: false });
      },
      (err) => {
        this.setState({
          data: {
            totalLeads: 0,
            activeLeads: 0,
            activeTransactions: 0,
            fundedTransactions: 0,
            last30DaysLeads: 0,
            yearToDateLeads: 0,
            averageTransactionSize: 0,
            fundedVolume: 0,
          },
          isLoading: false,
          isError: true,
        });
      }
    );
  }

  render() {
    const { classes } = this.props;
    const baseUrl = this.props.baseUrl ? this.props.baseUrl : "";
    const isIntegration = this.props.isIntegrationPlatform || false;

    const renderTile = (tile: Tile) => {
      return (
        <Grid item className={classes.tileContainer}>
          <Box
            className={classnames(classes.tile, {
              [classes.tileClickable]: !!tile.url,
              [classes.tileBorderDark]: !!tile.url,
              [classes.tileBorderLight]: !tile.url,
            })}
            boxShadow={10}
          >
            {!!tile.url ? (
              <Link
                to={tile.url}
                onClick={() => {
                  this.props.setTab &&
                    tile.index &&
                    this.props.setTab(tile.index);
                }}
                className={classes.link}
              >
                <Avatar
                  src={tile.image}
                  className={classnames(classes.tileAvatar, tile.cssClass)}
                ></Avatar>
              </Link>
            ) : (
              <Avatar
                src={tile.image}
                className={classnames(classes.tileAvatar, tile.cssClass)}
              ></Avatar>
            )}
            <Box className={classes.tileText}>
              <Typography variant="h2">{tile.title}</Typography>
              <Typography variant="h2" className={classes.tileNumber}>
                {this.state.isLoading && (
                  <CircularProgress
                    size={24}
                    thickness={1}
                    className={classes.progress}
                  ></CircularProgress>
                )}
                {!this.state.isLoading && (
                  <span
                    className={classnames({
                      [classes.tileNumberDark]: !!tile.url,
                      [classes.tileNumberLight]: !tile.url,
                    })}
                  >
                    {tile.value}
                  </span>
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>
      );
    };

    return (
      <Box
        className={classnames({
          [classes.expandedMenu]: this.props.menuExpanded,
          [classes.collapsedMenu]: !this.props.menuExpanded,
        })}
      >
        <CompanyLogo menuExpanded={this.props.menuExpanded}></CompanyLogo>
        <Box>
          <Typography className={classes.headerText} variant="h1">
            My Pipeline
          </Typography>
          <Typography variant="h6" className={classes.headerSubtitle}>
            This dashboard houses KPI metrics that breakdown Total Leads
            submitted into Active Leads (Pre-Application Companies), Active
            Transactions (Post-Application Companies), and Funded Transactions.
          </Typography>
        </Box>
        <Grid
          container
          className={classnames(classes.tiles, {
            [classes.tilesIntegration]: isIntegration,
          })}
          direction="column"
        >
          <Grid container className={classes.tilesGroup} direction="row">
            <Grid item>
              <Grid container className={classes.tilesGroup} direction="row">
                {renderTile({
                  title: "Total Leads",
                  value: Intl.NumberFormat("en-US").format(
                    this.state.data.totalLeads
                  ),
                  image: "/total-leads-graphic.svg",
                  cssClass: classes.tileAvatarTotalLeads,
                  url: baseUrl + "/all-leads",
                  index: 4,
                })}
                {renderTile({
                  title: "Active Leads",
                  value: Intl.NumberFormat("en-US").format(
                    this.state.data.activeLeads
                  ),
                  image: "/active-leads-graphic.svg",
                  cssClass: classes.tileAvatarActiveLeads,
                  url: baseUrl + "/active-leads",
                  index: 1,
                })}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.tilesGroup} direction="row">
                {renderTile({
                  title: "Active Transactions",
                  value: Intl.NumberFormat("en-US").format(
                    this.state.data.activeTransactions
                  ),
                  image: "/active-transactions-graphic.svg",
                  cssClass: classes.tileAvatarActiveTransactions,
                  url: baseUrl + "/active-transactions",
                  index: 2,
                })}
                {renderTile({
                  title: "Funded Transactions",
                  value: Intl.NumberFormat("en-US").format(
                    this.state.data.fundedTransactions
                  ),
                  image: "/funded-transactions-graphic.svg",
                  cssClass: classes.tileAvatarFundedTransactions,
                  url: baseUrl + "/funded-transactions",
                  index: 3,
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.tilesGroup} direction="row">
            <Grid item>
              <Grid container className={classes.tilesGroup} direction="row">
                {renderTile({
                  title: "Leads (Last 30 Days)",
                  value: Intl.NumberFormat("en-US").format(
                    this.state.data.last30DaysLeads
                  ),
                  image: "/leads-last-30-days.svg",
                  cssClass: classes.tileAvatarLast30DaysLeads,
                })}
                {renderTile({
                  title: "Leads (YTD)",
                  value: Intl.NumberFormat("en-US").format(
                    this.state.data.yearToDateLeads
                  ),
                  image: "/leads-ytd-graphic.svg",
                  cssClass: classes.tileAvatarYtdLeads,
                })}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container className={classes.tilesGroup} direction="row">
                {renderTile({
                  title: "Avg. Transaction Size",
                  value:
                    "$" +
                    numberToShortFormat(this.state.data.averageTransactionSize),
                  image: "/avg-transaction-size-graphic.svg",
                  cssClass: classes.tileAvatarAvgTransactionSize,
                })}
                {renderTile({
                  title: "Funded Volume",
                  value:
                    "$" + numberToShortFormat(this.state.data.fundedVolume),
                  image: "/funded-volume-graphic.svg",
                  cssClass: classes.tileAvatarFundedVolume,
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(MyPipeline);

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "../Auth";

function PrivateRoute(props: any) {
  const { children, loginUrl, ...rest } = props;
  return (
    <AuthConsumer>
      {({ isAuthenticated }) => {
        if (isAuthenticated()) {
          return <Route {...rest}>{children}</Route>;
        } else if (loginUrl) {
          return <Redirect to={loginUrl}></Redirect>;
        } else {
          return <Redirect to="/login"></Redirect>;
        }
      }}
    </AuthConsumer>
  );
}

export default PrivateRoute;

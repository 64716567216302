import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { formField } from "../../../../Theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    title: {
      textAlign: "center",
      marginTop: theme.spacing(8),
    },
    subtitle: {
      textAlign: "center",
      marginTop: theme.spacing(6),
      marginBottom: 19,
    },
    documentLines: {
      width: "100%",
    },
    documentLine: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      paddingLeft: 64,
      "& h6": {
        fontWeight: "bold",
      },
    },
    documentIndicator: {
      backgroundColor: theme.colors.darkBlue,
      height: 10,
      width: 10,
      borderRadius: 5,
      marginRight: theme.spacing(4),
      marginBottom: 2,
    },
    form: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    dropzoneContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(4),
      "& .MuiTypography-subtitle1": {
        marginTop: theme.spacing(4),
        ...theme.typography.h6,
        fontWeight: "bold",
      },
    },
    dropzoneRoot: {
      backgroundColor: theme.colors.lightGrey,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "unset",
      height: 120,
      "&:focus": {
        outline: "none",
      },
    },
    dropzoneRootColumn: {
      flexDirection: "column",
    },
    dropzoneText: {
      fontSize: 17,
      fontWeight: "normal",
    },
    dropzoneFilePreview: {
      marginTop: theme.spacing(2),
      width: "100%",
      margin: 0,
    },
    dropzoneFilePreviewItem: {
      "&.MuiGrid-item": {
        padding: "7px 0",
      },
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        "& > div": {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          "& > .MuiChip-root": {
            width: "100%",
            height: 44,
            borderRadius: 22,
            backgroundColor: "#9efdff",
            display: "flex",
            justifyContent: "start",
            marginLeft: 60,
            "& .MuiChip-label": {
              width: "100%",
              textAlign: "center",
              color: "#4e4e4e",
              fontWeight: 300,
            },
            "& svg": {
              marginLeft: 13,
              color: "#79c8c9",
            },
          },
        },
      },
      "& .MuiDropzonePreviewList-removeButton": {
        top: 7,
        left: 0,
        width: 44,
        height: 44,
        color: theme.palette.secondary.main,
        opacity: 1,
        boxShadow: "none",
        [theme.breakpoints.down("md")]: {
          left: 144,
        },
      },
    },
    dropzonePreviewSelect: {
      ...formField,
      width: "100%",
      height: 43,
      marginLeft: theme.spacing(4),
      color: theme.palette.text.primary,
      "&:hover": {
        "& .MuiSelect-icon": {
          color: theme.colors.darkBlue,
        },
      },
    },
    dropzoneDisabled: {
      pointerEvents: "none",
      cursor: "default",
    },
    fileTypeText: {
      fontWeight: "bold",
      width: 88,
      marginBottom: 8,
      textAlign: "left",
      whiteSpace: "nowrap",
      marginLeft: 0,
    },
    button: {
      width: 370,
      marginTop: theme.spacing(8),
      color: theme.palette.common.white,
      textTransform: "capitalize",
      [theme.breakpoints.down(360)]: {
        width: "100%",
      },
    },
    details: {
      marginTop: 76,
      width: "100%",
      color: "#969899",
      borderBottom: "solid 0.5px #707070",
      marginBottom: theme.spacing(4),
      "& .MuiAccordion-root": {
        boxShadow: "none",
      },
      "& h6": {
        color: "#969899",
        fontWeight: 500,
      },
      "&:hover": {
        borderBottom: `solid 1px ${theme.colors.darkBlue}`,
        "& svg": {
          color: theme.colors.darkBlue,
        },
      },
    },
    detailsText: {
      width: "100%",
      color: "#848484",
      "& a": {
        color: "#848484",
        textDecoration: "none",
      },
    },
    detailsBoldText: {
      fontWeight: 600,
    },
    detailsItalicText: {
      fontStyle: "italic",
    },
    errors: {
      color: theme.palette.secondary.main,
    },
    submitting: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 10000,
    },
  })
);

export default useStyles;

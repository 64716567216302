import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import TimelineIcon from "@material-ui/icons/Timeline";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, useHistory } from "react-router-dom";
import { MyPipeline } from "../../Components/Dashboards";
import {
  ActiveLeads,
  ActiveTransactions,
  AllLeads,
  FundedTransactions,
  StalledLeads,
  StalledTransactions,
} from "../../Components/DealPipeline";
import FinanceApp from "../../Components/FinanceApp";
import { AuthConsumer } from "../Auth";
import CopyIcon from "../Common/CopyIcon";
import useStyles from "./layoutWithMenuIntegration.style";

interface LayoutWithMenuIntegrationMenuProps {
  url: string;
  updateNotificationsToggle: () => void;
  notificationsToggle: boolean;
}
function LayoutWithMenuIntegration(props: LayoutWithMenuIntegrationMenuProps) {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);
  const { url, updateNotificationsToggle, notificationsToggle } = props;
  const menuExpanded = false;
  const isIntegrationPlatform = true;
  const [avatarMenu, setAvatarMenu] = React.useState<null | HTMLElement>(null);
  const [tabMenu, setTabMenu] = React.useState<null | HTMLElement>(null);
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, [copied]);

  useEffect(() => {
    const path = window.location.pathname;
    switch (path) {
      case "/integration/active-leads":
        setTab(1);
        break;
      case "/integration/active-transactions":
        setTab(2);
        break;
      case "/integration/funded-transactions":
        setTab(3);
        break;
      case "/integration/all-leads":
        setTab(4);
        break;
      case "/integration/stalled-leads":
        setTab(5);
        break;
      case "/integration/stalled-transactions":
        setTab(6);
        break;
      default:
        setTab(0);
        break;
    }
  }, []);
  const openAvatarMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarMenu(event.currentTarget);
  };
  const closeAvatarMenu = () => {
    setAvatarMenu(null);
  };
  const openTabMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTabMenu(event.currentTarget);
  };
  const closeTabMenu = () => {
    setTabMenu(null);
  };
  const history = useHistory();

  const newAppButtonClick = () => {
    history.push(`${url}/finance-application`);
    setTabMenu(null);
  };

  const menuItems = [
    {
      title: "My Pipeline",
      url: "/dashboard",
      icon: <TimelineIcon className={classes.menuIcon}></TimelineIcon>,
    },
    {
      title: "Active Leads",
      url: "/active-leads",
      icon: <FlashOnIcon className={classes.menuIcon}></FlashOnIcon>,
    },
    {
      title: "Active Transactions",
      url: "/active-transactions",
      icon: <CheckCircleIcon className={classes.menuIcon}></CheckCircleIcon>,
    },
    {
      title: "Funded Transactions",
      url: "/funded-transactions",
      icon: <AttachMoneyIcon className={classes.menuIcon}></AttachMoneyIcon>,
    },
    {
      title: "All",
      url: "/all-leads",
      icon: (
        <SupervisedUserCircleIcon
          className={classes.menuIcon}
        ></SupervisedUserCircleIcon>
      ),
    },
    {
      title: "Stalled Leads",
      url: "/stalled-leads",
      icon: <Brightness4Icon className={classes.menuIcon}></Brightness4Icon>,
    },
    {
      title: "Stalled Transactions",
      url: "/stalled-transactions",
      icon: <NightsStayIcon className={classes.menuIcon}></NightsStayIcon>,
    },
  ];

  return (
    <>
      <Hidden smDown>
        <div className={classes.root}>
          <Tabs
            value={tab}
            indicatorColor="secondary"
            onChange={(event: any, newValue: number) => {
              setTab(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            {menuItems.map((menuItem, index) => (
              <Tab
                key={index}
                label={menuItem.title}
                component={Link}
                to={`${url}${menuItem.url}`}
                className={classes.tab}
              />
            ))}
          </Tabs>
          <AuthConsumer>
            {({ isAuthenticated, getUser, signout }) => {
              if (isAuthenticated()) {
                const financeAppUrl = getUser()?.financeApplicationUrl;

                return (
                  <Box className={(classes.userAvatarContent, classes.logOut)}>
                    {(financeAppUrl?.length || 0) > 0 ? (
                      <Box className={classes.centerAlign}>
                        <Tooltip title={copied ? "Copied!" : "Copy"}>
                          <Button
                            className={classes.copyApp}
                            onClick={() => {
                              if (financeAppUrl) {
                                navigator.clipboard.writeText(financeAppUrl);
                                setCopied(true);
                              }
                            }}
                            startIcon={<CopyIcon />}
                          >
                            Copy application link
                          </Button>
                        </Tooltip>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.newAppButton}
                          onClick={newAppButtonClick}
                        >
                          New Application
                        </Button>
                      </Box>
                    ) : null}
                    <Avatar className={classes.userAvatarIcon}>
                      <div className={classes.userAvatarBorder}></div>
                      <AccountCircleIcon></AccountCircleIcon>
                    </Avatar>
                    <Box className={classes.userAvatarName}>
                      <Typography variant="body1">
                        {getUser()?.firstName}
                      </Typography>
                      <Typography variant="overline">
                        {getUser()?.companyName}
                      </Typography>
                    </Box>
                    <IconButton
                      size="small"
                      className={classnames(classes.userAvatarDots, {
                        [classes.userAvatarDotsCollapsed]: !menuExpanded,
                      })}
                      onClick={openAvatarMenu}
                    >
                      <MoreVertIcon></MoreVertIcon>
                    </IconButton>
                    <Menu
                      id="avatar-menu"
                      anchorEl={avatarMenu}
                      keepMounted
                      className={classes.menuPosition}
                      open={Boolean(avatarMenu)}
                      onClose={closeAvatarMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem onClick={() => signout()}>
                        <ListItemIcon>
                          <MeetingRoomIcon></MeetingRoomIcon>
                        </ListItemIcon>
                        <ListItemText>Log Out</ListItemText>
                      </MenuItem>
                      <MenuItem className={classes.notificationsToggle}>
                        <ListItemIcon>
                          <Switch
                            checked={notificationsToggle}
                            onChange={updateNotificationsToggle}
                            color="primary"
                            name="notificationsToggle"
                          />
                        </ListItemIcon>
                        <ListItemText>Notifications</ListItemText>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <ContactSupportIcon></ContactSupportIcon>
                        </ListItemIcon>
                        <ListItemText>Support</ListItemText>
                      </MenuItem>
                    </Menu>
                  </Box>
                );
              } else {
                return <></>;
              }
            }}
          </AuthConsumer>
        </div>
      </Hidden>
      <Hidden mdUp>
        <Box className={classnames(classes.topMenuBar)}>
          <IconButton
            className={classnames(classes.drawerToggleMobile)}
            onClick={openTabMenu}
          >
            {<MenuIcon fontSize="large"></MenuIcon>}
          </IconButton>
        </Box>
        <Menu
          id="tab-menu"
          anchorEl={tabMenu}
          keepMounted
          open={Boolean(tabMenu)}
          onClose={closeTabMenu}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {menuItems.map((menuItem, index) => (
            <MenuItem
              component={Link}
              to={`${url}${menuItem.url}`}
              key={index}
              onClick={() => setTabMenu(null)}
            >
              {menuItem.icon}
              <ListItemText className={classes.menuItem}>
                {menuItem.title}
              </ListItemText>
            </MenuItem>
          ))}
          <Box boxShadow={1} className={classes.dividerContainer}>
            <Box boxShadow={2}>
              <Box boxShadow={3}></Box>
            </Box>
          </Box>
          <Divider className={classes.divider}></Divider>
          <AuthConsumer key="mobile-menu">
            {({ isAuthenticated, getUser, signout }) => {
              const financeAppUrl = getUser()?.financeApplicationUrl;

              if (isAuthenticated()) {
                return (
                  <Box>
                    {(financeAppUrl?.length || 0) > 0 ? (
                      <>
                        <MenuItem className={classes.centerAlign}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.newAppButton}
                            onClick={newAppButtonClick}
                          >
                            New Application
                          </Button>
                        </MenuItem>
                        <MenuItem className={classes.centerAlign}>
                          <Tooltip title={copied ? "Copied!" : "Copy"}>
                            <Button
                              className={classes.copyApp}
                              onClick={() => {
                                if (financeAppUrl) {
                                  navigator.clipboard.writeText(financeAppUrl);
                                  setCopied(true);
                                }
                                setTabMenu(null);
                              }}
                              startIcon={<CopyIcon />}
                            >
                              Copy application link
                            </Button>
                          </Tooltip>
                        </MenuItem>
                      </>
                    ) : null}
                    <MenuItem onClick={() => signout()}>
                      <ListItemIcon>
                        <MeetingRoomIcon></MeetingRoomIcon>
                      </ListItemIcon>
                      <ListItemText>Log Out</ListItemText>
                    </MenuItem>
                    <MenuItem className={classes.notificationsToggle}>
                      <ListItemIcon>
                        <Switch
                          checked={notificationsToggle}
                          onChange={updateNotificationsToggle}
                          color="primary"
                          name="notificationsToggle"
                        />
                      </ListItemIcon>
                      <ListItemText className={classes.menuItem}>
                        Notifications
                      </ListItemText>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <ContactSupportIcon></ContactSupportIcon>
                      </ListItemIcon>
                      <ListItemText>Support</ListItemText>
                    </MenuItem>
                  </Box>
                );
              } else {
                return <></>;
              }
            }}
          </AuthConsumer>
        </Menu>
      </Hidden>
      <Route exact path={`/integration`}>
        <MyPipeline
          menuExpanded={menuExpanded}
          baseUrl={`${url}`}
          isIntegrationPlatform={isIntegrationPlatform}
          setTab={setTab}
        />
      </Route>
      <Route exact path={`/integration/`}>
        <Redirect to="/integration"></Redirect>
      </Route>
      <Route exact path={`${url}/dashboard`}>
        <MyPipeline
          menuExpanded={menuExpanded}
          baseUrl={`${url}`}
          isIntegrationPlatform={isIntegrationPlatform}
          setTab={setTab}
        />
      </Route>
      <Route exact path={`${url}/active-leads`}>
        <ActiveLeads menuExpanded={menuExpanded} />
      </Route>
      <Route exact path={`${url}/stalled-leads`}>
        <StalledLeads menuExpanded={menuExpanded} />
      </Route>
      <Route exact path={`${url}/active-transactions`}>
        <ActiveTransactions menuExpanded={menuExpanded} />
      </Route>
      <Route exact path={`${url}/stalled-transactions`}>
        <StalledTransactions menuExpanded={menuExpanded} />
      </Route>
      <Route exact path={`${url}/funded-transactions`}>
        <FundedTransactions menuExpanded={menuExpanded} />
      </Route>
      <Route exact path={`${url}/all-leads`}>
        <AllLeads menuExpanded={menuExpanded} />
      </Route>
      <AuthConsumer>
        {({ isAuthenticated, getUser }) => {
          const financeAppUrl = getUser()?.financeApplicationUrl || "";
          return isAuthenticated() && financeAppUrl.length > 0 ? (
            <Route exact path={`${url}/finance-application`}>
              <FinanceApp financeAppUrl={financeAppUrl} isIntegration={true} />
            </Route>
          ) : null;
        }}
      </AuthConsumer>
    </>
  );
}

export default LayoutWithMenuIntegration;

import axios from "axios";
import config from "../config";
import logger from "../Helpers/logger";

const postWithFiles = (
  url: string,
  data: any,
  files: any,
  fakeData?: any
): Promise<any> => {
  const endpoint = buildEndpointUrl(url);
  if (config.uploadApi.fakeIt) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => resolve(fakeData || { succes: true }), 500);
    });
  } else {
    const formData = new FormData();
    Object.keys(data).map((key) =>
      Array.isArray(data[key])
        ? data[key].map((value: any, index: number) =>
            formData.append(`${key}[${index}]`, value)
          )
        : formData.append(key, data[key] === null ? "" : data[key])
    );
    Object.keys(files).map((key) => formData.append(key, files[key]));
    return axios
      .post(endpoint, formData, {
        headers: getHeaders(config.uploadApi.uploadDocumentsCode || ""),
      })
      .then((response) => response.data)
      .catch((err) => {
        logger.logError(err);
        throw err;
      });
  }
};

const buildEndpointUrl = (url: string) => `${config.uploadApi.url}${url}`;
const getHeaders = (key: string) => ({ "x-functions-key": key });

export { postWithFiles };

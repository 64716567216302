import React from "react";
import { getAll } from "../../../Api";
import withData from "../../Data/withData";
import BaseView from "../common/BaseView";
import { columns, columnsPVD } from "./config";

function View(props: any) {
  return (
    <BaseView
      columns={columns}
      columnsPVD={columnsPVD}
      title="All"
      subtitle="This is a list of all companies submitted by you."
      {...props}
    ></BaseView>
  );
}

export default withData(View, getAll);

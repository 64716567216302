import { Column } from "material-table";
import { Data } from "../../../Helpers/deal-pipelines";
import {
  estFundDateToDealerNotes,
  internalToDisplayStatus,
  statusToStyle,
} from "../../../Helpers/inventory-tracker";
import { compareStrings } from "../../../Helpers/utils";
import { renderChip } from "../../DealPipeline/common/columns";
import { centerAlignCellStyle } from "../../DealPipeline/common/columns.style";

const columns: Array<Column<Data>> = [
  { field: "compName", title: "Model Number", removable: false, width: 110 },
  {
    field: "acctInvDesc",
    title: "Serial Number",
    removable: false,
    width: 170,
  },
  {
    title: "Current Status",
    render: (rowData) =>
      renderChip(rowData.acctStatus, statusToStyle, internalToDisplayStatus),
    customFilterAndSearch: (filter: any, rowData: Data) => {
      const value = internalToDisplayStatus(rowData.acctStatus) || "";
      return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    },
    customSort: (row1, row2) =>
      compareStrings(
        internalToDisplayStatus(row1.acctStatus),
        internalToDisplayStatus(row2.acctStatus)
      ),
    width: 180,
  },
  {
    field: "acctCost",
    title: "Unit Price",
    type: "currency",
    cellStyle: centerAlignCellStyle,
    headerStyle: centerAlignCellStyle,
    width: 110,
  },
  { field: "vendorName", title: "Dealer ID", width: 220 },
  { field: "acctInvEqLoc", title: "Eq. Location", width: 100 },
  { field: "acctInvDeliv", title: "Date Delivered", type: "date", width: 95 },
  {
    field: "acctInvFpExp",
    title: "Floorplan Expiration",
    type: "date",
    width: 95,
  },
  {
    field: "lastUpdated",
    title: "Last Updated",
    defaultSort: "desc",
    type: "datetime",
    width: 120,
  },
  {
    title: "Dealer Notes",
    render: (rowData) => estFundDateToDealerNotes(rowData.acctOutlook),
    customFilterAndSearch: (filter: any, rowData: Data) => {
      const value = estFundDateToDealerNotes(rowData.acctOutlook) || "";
      return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    },
    customSort: (row1, row2) =>
      compareStrings(
        internalToDisplayStatus(row1.acctOutlook),
        internalToDisplayStatus(row2.acctOutlook)
      ),
    width: 120,
  },
];
const columnsPVD: Array<Column<Data>> = [];

export { columns, columnsPVD };

import { Avatar, Box, Container, Grid } from "@material-ui/core";
import classnames from "classnames";
import React, { useMemo } from "react";
import { useBrandedPortal } from "../../Helpers/hooks/useBrandedPortal";
import useStyles from "./layoutNonMember.style";

const LayoutNonMember = (props: any) => {
  const classes = useStyles();

  const brandedPortal = useBrandedPortal();
  const isBrandedPortal = brandedPortal.isBrandedPortal;
  const logoPath = useMemo(
    () =>
      isBrandedPortal
        ? brandedPortal.logoVerticalPath
        : "/powered_by_taycor_logo_white.png",
    [isBrandedPortal, brandedPortal.logoVerticalPath]
  );

  return (
    <Container maxWidth={false} disableGutters={true} className={classes.root}>
      <Grid container direction="column">
        <Grid item className={classes.content}>
          <Grid container>
            <Grid item className={classes.leftHalf}>
              <Box
                className={classnames(classes.leftHalfBackground, {
                  [classes.leftHalfBackgroundMidpoint]: isBrandedPortal,
                })}
              >
                <Avatar
                  variant="square"
                  src={logoPath}
                  className={classnames(classes.logo, {
                    [classes.logoMidpoint]: isBrandedPortal,
                  })}
                ></Avatar>
              </Box>
            </Grid>
            <Grid item className={classes.rightHalf}>
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LayoutNonMember;

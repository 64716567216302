import React from "react";
import { getActiveTransactions } from "../../../Api";
import withData from "../../Data/withData";
import BaseView from "../common/BaseView";
import { columns, columnsPVD } from "./config";

function View(props: any) {
  return (
    <BaseView
      columns={columns}
      columnsPVD={columnsPVD}
      title="Active Transactions"
      subtitle="These are active companies that have progressed into final underwriting and contract creation."
      {...props}
    ></BaseView>
  );
}

export default withData(View, getActiveTransactions);

import React, { Component } from "react";
import auth, { AuthService } from "../../Services/auth";

const AuthContext = React.createContext<AuthService>({
  signin: (
    u: string,
    p: string,
    d?: string,
    r?: string,
    s: () => void = () => {},
    e: () => void = () => {}
  ) => {},
  signout: (redirectUrl?: string) => {},
  isAuthenticated: () => false,
  isInRole: () => false,
  isInRoles: () => false,
  getUser: () => null,
  getAccessToken: () => null,
  setAutomaticTokenRefresh: () => {},
});

export const AuthConsumer = AuthContext.Consumer;

interface AuthProviderState {
  userSignedIn: boolean;
}

export class AuthProvider extends Component<any, AuthProviderState, any> {
  private authService: any;

  constructor(props: any) {
    super(props);
    this.state = { userSignedIn: false };
    this.authService = auth;
  }

  componentDidMount() {
    this.authService.setAutomaticTokenRefresh();
  }

  render() {
    return (
      <AuthContext.Provider value={this.authService}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

import { Theme } from "@material-ui/core";

const styles = (theme: Theme) => ({
  expandedMenu: {
    marginLeft: 0,
    transition: theme.transitions.create("margin-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  collapsedMenu: {
    marginLeft: 0,
    transition: theme.transitions.create("margin-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  headerText: {
    marginTop: theme.spacing(10),
    marginLeft: 50,
    [theme.breakpoints.down(breakpoints.first)]: {
      marginLeft: 20,
    },
  },
  headerSubtitle: {
    marginTop: theme.spacing(2),
    marginLeft: 50,
    [theme.breakpoints.down(breakpoints.first)]: {
      marginLeft: 20,
    },
  },
  tiles: {
    marginTop: 85,
    margin: theme.spacing(3),
    [theme.breakpoints.down(breakpoints.first)]: {
      margin: 0,
    },
  },
  tilesGroup: {
    justifyContent: "center",
    [theme.breakpoints.down(breakpoints.first)]: {
      justifyContent: "center",
    },
  },
  tileContainer: {
    textAlign: "center" as "center",
    margin: theme.spacing(5, 9),
    [theme.breakpoints.down(breakpoints.first)]: {
      marginLeft: 20,
    },
  },
  tile: {
    width: 338,
    height: 267,
    color: "#8f8f8f",
    borderRadius: theme.spacing(2),
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.common.white,
    borderBottomWidth: theme.spacing(2),
    borderBottomStyle: "solid" as "solid",
    [theme.breakpoints.down(breakpoints.first)]: {
      width: 300,
    },
    [theme.breakpoints.down(breakpoints.second)]: {
      width: 250,
    },
    [theme.breakpoints.down(breakpoints.third)]: {
      width: 200,
    },
  },
  tileAvatar: {
    width: 148,
    height: 148,
    paddingBottom: theme.spacing(1),
    paddingLeft: 81.5,
    paddingRight: 81.5,
    paddingTop: theme.spacing(4),
    opacity: 0.8,
    fontSize: 100,
    backgroundColor: theme.palette.common.white,
    color: theme.colors.darkBlue,
    "& img": {
      width: 128,
      height: "auto",
    },
  },
  tileText: {
    display: "flex",
    flexDirection: "column" as "column",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    width: 250,
    [theme.breakpoints.down(breakpoints.third)]: {
      "& h2": {
        fontSize: 18,
      },
    },
  },
  tileNumber: {
    marginTop: 6,
    marginBottom: 6,
  },
  tileNumberLight: {
    color: theme.colors.lightBlue,
    fontWeight: 500,
  },
  tileNumberDark: {
    color: theme.colors.darkBlue,
    fontWeight: 500,
  },
  tileBorderLight: {
    borderBottomColor: theme.colors.lightBlue,
  },
  tileBorderDark: {
    borderBottomColor: theme.colors.darkBlue,
  },
  progress: {
    color: theme.colors.darkBlue,
  },
  spacer: {
    display: "none",
    [theme.breakpoints.up("xl")]: {
      display: "flex",
      visibility: "hidden" as "hidden",
    },
  },
  tileAvatarInventoryLine: {
    width: 200,
    "& img": {
      width: 200,
    },
  },
});

const breakpoints = {
  first: 650,
  second: 432,
  third: 382,
};

export default styles;

export default {
  ai: {
    disabled: true,
  },
  ga: {
    id: "",
    enabled: false,
  },
  pvd: {
    enabled: false,
  },
  midpointPortal: "",
};

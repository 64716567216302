import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      color: theme.palette.common.black,
    },
  })
);

export default useStyles;

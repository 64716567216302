import { Typography } from "@material-ui/core";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Role } from "../../Services/auth";
import { AuthConsumer } from "../Auth";

interface RoleRouteProps {
  role: Role;
}

function RoleRoute(props: any) {
  const { children, role, ...rest } = props;
  return (
    <AuthConsumer>
      {({ isInRole, isAuthenticated, isInRoles }) => {
        if (!isAuthenticated()) {
          return <Redirect to="/login" />;
        }
        if (isInRoles(role)) {
          return <Route {...rest}>{children}</Route>;
        } else {
          return (
            <Route {...rest}>
              <Typography>Unauthorized</Typography>
            </Route>
          );
        }
      }}
    </AuthConsumer>
  );
}

export default RoleRoute;

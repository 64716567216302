import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import LastPageIcon from "@material-ui/icons/LastPage";
import RemoveIcon from "@material-ui/icons/Remove";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import SearchIcon from "@material-ui/icons/Search";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import { Autocomplete } from "@material-ui/lab";
import { DefaultThemeProvider } from "@taycor/ui-library";
import classNames from "classnames";
import MaterialTable, { Column } from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import config from "../../../config";
import { Data } from "../../../Helpers/deal-pipelines";
import { checkForUserPVD } from "../../../Helpers/hooks/checkForUserPVD";
import { ActivityType } from "../../../Helpers/types";
import {
  collapseUserLeadSources,
  expandUserLeadSources
} from "../../../Helpers/users";
import { compareStrings } from "../../../Helpers/utils";
import theme from "../../../Theme";
import { CompanyLogo } from "../../Common";
import Permission from "../../Permission";
import { columnsPVD } from "../ActiveLeads/config";
import useStyles, {
  headerStyle,
  searchFieldStyle,
  tableStyle
} from "./baseView.style";
import LeadSourceFilter from "./children/LeadSourceFilter";
import Details from "./Details";
import InventoryDetails from "./InventoryDetails";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SelectedItem {
  id: string;
  type: ActivityType;
}

interface BaseViewProps {
  title: string;
  subtitle: string;
  columns: Array<Column<Data>>;
  columnsPVD: Array<Column<Data>>;
  data: Array<any>;
  isLoading: boolean;
  vendors: Array<any>;
  onReload: () => void;
  menuExpanded: boolean;
}

function BaseView(props: BaseViewProps) {
  const classes = useStyles();

  const { columns, title, subtitle, menuExpanded } = props;

  const [vendors, setVendors] = useState<Array<any>>([]);
  const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);
  const [scrollToTop, setScrollToTop] = useState<boolean>(true);

  // show 2 fixed columns when the table does not fit the screen
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const tableMinWidth = columns.reduce(
    (acc, col) => acc + (col.width as number),
    0
  );
  const minWidth =
    tableMinWidth + // min width of the table
    (menuExpanded ? theme.layout.leftDrawerWidth : theme.layout.menuWidth) + // left drawer width
    theme.pipelines.spacing.left + // margin left of the pipeline view
    (!!selectedItem
      ? isMdScreen
        ? theme.layout.rightDrawerNarrowWidth
        : theme.layout.rightDrawerWidth
      : theme.pipelines.spacing.right); // width of right drawer or margin right
  const isAboveMinWidth = useMediaQuery(`(min-width:${minWidth}px)`);
  let fixedColumnsOptions = isAboveMinWidth ? undefined : { left: 2 };

  let companyIdColumn;
  for (let i = 0; i < columns.length; i++) {
    if (columns[i].field === "companyId") {
      companyIdColumn = columns[i];
      columns[i].hidden = false;
      columns[i].removable = false;
      break;
    }
  }

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (isSmallScreen) {
    fixedColumnsOptions = undefined;
    if (companyIdColumn) {
      companyIdColumn.hidden = true;
      companyIdColumn.removable = true;
    }
  }
  const isIntegration = window.location.href.indexOf("integration") !== -1;

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
      setScrollToTop(false);
    }
  }, [scrollToTop]);

  useEffect(() => {
    if (!(checkForUserPVD() || config.pvd.enabled)) {
      const defaultVendors = expandUserLeadSources(
        JSON.parse(sessionStorage.getItem("vendors") || "[]"),
        props.vendors
      );
      setVendors(defaultVendors);
    }
  }, [props.vendors]);

  useEffect(() => {
    if (checkForUserPVD() || config.pvd.enabled) {
      sessionStorage.setItem("vendors", JSON.stringify(vendors));
      const sessionItems = sessionStorage.getItem("vendors");
      if (sessionItems && vendors.length > 0) {
        props.onReload();
      }
    } else {
      sessionStorage.setItem(
        "vendors",
        JSON.stringify(collapseUserLeadSources(vendors))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors]);
  const handlePVDIds = (values: any) => {
    setVendors([...values]);
  };

  const search = () => {
    if (checkForUserPVD() || config.pvd.enabled) {
      return (
        <DefaultThemeProvider>
          <LeadSourceFilter handlePVDIds={handlePVDIds}></LeadSourceFilter>
        </DefaultThemeProvider>
      );
    } else {
      return (
        <>
          <Autocomplete
            className={classes.autocomplete}
            multiple
            options={props.vendors.map((v) => v.name).sort(compareStrings)}
            disableCloseOnSelect
            onChange={(_: any, value: any) => {
              setVendors(
                expandUserLeadSources(value, props.vendors, [...vendors])
              );
            }}
            value={vendors}
            classes={{
              option: classes.autocompleteOption,
            }}
            renderInput={(props: any) => <TextField {...props} />}
            renderOption={(option: any, s: any) => (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={s.selected}
                />
                {option}
              </>
            )}
          ></Autocomplete>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={props.onReload}
            className={classes.actionsButton}
          >
            Reload
          </Button>
        </>
      );
    }
  };

  return (
    <Box
      className={classNames(classes.root, {
        [classes.rootDrawerOpen]: !!selectedItem,
      })}
    >
      <SimpleBar
        className={classNames(classes.simplebar, {
          [classes.simplebarIntegration]: isIntegration,
        })}
      >
        <CompanyLogo menuExpanded={props.menuExpanded}></CompanyLogo>
        <Box
          className={classNames(classes.tableContainer, {
            [classes.tableContainerMenuCollapsed]:
              !selectedItem && !menuExpanded,
            [classes.tableContainerNarrow]: !!selectedItem && menuExpanded,
            [classes.tableContainerNarrowMenuCollapsed]:
              !!selectedItem && !menuExpanded,
            [classes.tableContainerIntegration]: isIntegration,
          })}
        >
          <Permission role="Admin">
            <Box className={classes.actions}>
              <Typography className={classes.actionsLabel} variant="h6">
                {checkForUserPVD() || config.pvd.enabled
                  ? "Filter results:"
                  : "Vendors"}
              </Typography>
              {search()}
            </Box>
          </Permission>
          <MaterialTable
            columns={
              checkForUserPVD() || config.pvd.enabled
                ? columns
                : columns.filter(
                    (c) => !columnsPVD.find((cp) => c.field === cp.field)
                  )
            }
            data={props.data}
            isLoading={props.isLoading}
            title={
              <>
                <Typography variant="h1">{title}</Typography>
                <Typography variant="h6" className={classes.tableSubtitle}>
                  {subtitle}
                </Typography>
              </>
            }
            options={{
              padding: "dense",
              filtering: false,
              pageSize: 50,
              paginationType: "stepped",
              showFirstLastPageButtons: false,
              pageSizeOptions: [50],
              tableLayout: fixedColumnsOptions ? "fixed" : "auto",
              columnsButton: true,
              thirdSortClick: false,
              draggable: false,
              emptyRowsWhenPaging: false,
              searchFieldVariant: "outlined",
              searchFieldStyle: searchFieldStyle(theme),
              headerStyle: headerStyle,
              rowStyle: (data, index) =>
                selectedItem && selectedItem.id === data.id
                  ? {
                      backgroundColor: theme.colors.darkBlue,
                      color: theme.palette.common.white,
                    }
                  : index % 2 === 1
                  ? { backgroundColor: theme.palette.common.white }
                  : { backgroundColor: theme.colors.lightGrey },
            }}
            onRowClick={(event, rowData) => {
              setSelectedItem({
                id: rowData.id,
                type:
                  rowData.acctType === "Inventory"
                    ? "inventory"
                    : rowData.accountId
                    ? "transaction"
                    : "lead",
              });
            }}
            onChangePage={() => {
              setScrollToTop(true);
            }}
            style={tableStyle}
            icons={{
              Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
              Check: forwardRef((props, ref) => (
                <CheckIcon {...props} ref={ref} />
              )),
              Clear: forwardRef((props, ref) => (
                <ClearIcon {...props} ref={ref} />
              )),
              Delete: forwardRef((props, ref) => (
                <DeleteOutlineIcon {...props} ref={ref} />
              )),
              DetailPanel: forwardRef((props, ref) => (
                <ChevronRightIcon {...props} ref={ref} />
              )),
              Edit: forwardRef((props, ref) => (
                <EditIcon {...props} ref={ref} />
              )),
              Export: forwardRef((props, ref) => (
                <SaveAltIcon {...props} ref={ref} />
              )),
              Filter: forwardRef((props, ref) => (
                <FilterListIcon {...props} ref={ref} />
              )),
              FirstPage: forwardRef((props, ref) => (
                <FirstPageIcon {...props} ref={ref} />
              )),
              LastPage: forwardRef((props, ref) => (
                <LastPageIcon {...props} ref={ref} />
              )),
              NextPage: forwardRef((props, ref) => (
                <ChevronRightIcon {...props} ref={ref} />
              )),
              PreviousPage: forwardRef((props, ref) => (
                <ChevronLeftIcon {...props} ref={ref} />
              )),
              ResetSearch: forwardRef((props, ref) => (
                <ClearIcon {...props} ref={ref} />
              )),
              Search: forwardRef((props, ref) => (
                <SearchIcon {...props} ref={ref} />
              )),
              SortArrow: forwardRef((props, ref) => (
                <KeyboardArrowDownIcon {...props} ref={ref} />
              )),
              ThirdStateCheck: forwardRef((props, ref) => (
                <RemoveIcon {...props} ref={ref} />
              )),
              ViewColumn: forwardRef((props, ref) => (
                <ViewColumnIcon {...props} ref={ref} />
              )),
            }}
          ></MaterialTable>
        </Box>
      </SimpleBar>
      {selectedItem && selectedItem.type !== "inventory" && (
        <Details
          activityId={selectedItem.id}
          activityType={selectedItem.type}
          onCloseDrawer={() => setSelectedItem(null)}
        ></Details>
      )}
      {selectedItem && selectedItem.type === "inventory" && (
        <InventoryDetails
          itemId={selectedItem.id}
          onCloseDrawer={() => setSelectedItem(null)}
        ></InventoryDetails>
      )}
    </Box>
  );
}

export default BaseView;

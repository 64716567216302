import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "./financeApp.style";
import classnames from "classnames";

interface FinanceAppProps {
  financeAppUrl: string;
  isIntegration?: boolean;
}

const FinanceApp = (props: FinanceAppProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <iframe
        src={props.financeAppUrl}
        title="Finance Application"
        className={classnames(classes.iframe, {
          [classes.integrationIframe]: !!props.isIntegration,
        })}
      ></iframe>
    </Box>
  );
};

export default FinanceApp;

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 114,
      width: `calc(100vw - ${theme.layout.leftDrawerWidth}px - 104px)`,
      [theme.breakpoints.down("sm")]: {
        width: `calc(100vw - 102px)`,
      },
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(14),
      "& div.MuiPaper-root": {
        // title text
        "& > div.MuiToolbar-root": {
          paddingLeft: 0,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse",
          },
          "& fieldset.MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& > div:first-child": {
            width: "78%",
            paddingRight: 24,
          },
          "& > div:nth-child(2)": {
            display: "none",
          },
        },
        // box shadow and margin for table content
        "& > div:nth-child(2)": {
          marginTop: theme.spacing(12),
          boxShadow: "0px 6px 20px 0 rgba(193, 207, 217, 0.2)",
          borderRadius: 10,
          //table margins
          paddingLeft: 134,
          paddingRight: 134,
          [theme.breakpoints.down("md")]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
          // fixed columns
          "& > div > div > div:nth-child(2)": {
            boxShadow: "15px 6px 20px 0 rgba(193, 207, 217, 0.2) !important",
            paddingLeft: theme.spacing(4),
          },
        },
        // center align pagination
        "& td.MuiTablePagination-root": {
          border: "none",
          "& div.MuiTablePagination-toolbar": {
            display: "flex",
            justifyContent: "center",
            "& div.MuiTablePagination-spacer": {
              flex: 0,
            },
          },
        },
        // table header
        "& table th": {
          fontSize: 14,
          fontWeight: "bold",
          paddingTop: 8,
          paddingBottom: 7,
          backgroundColor: theme.colors.lightGrey,
          borderBottom: "none",
          "& .MuiTableSortLabel-root": {
            height: 42,
            paddingLeft: theme.spacing(1),
            "& svg.MuiSvgIcon-root.MuiTableSortLabel-icon": {
              color: theme.palette.secondary.main,
            },
          },
          "& .MuiTableSortLabel-active": {
            backgroundColor: theme.palette.common.white,
          },
        },
        // table content row
        "& table tbody tr": {
          height: 114,
        },
        // cell style
        "& table tr td": {
          borderBottom: "none",
          "& svg": {
            color: theme.colors.darkGrey,
          },
          "& > h6:last-child": {
            borderBottom: `4px solid ${theme.colors.lightGrey}`,
          },
        },
        "& table tr td:first-child": {
          verticalAlign: "baseline",
          "& h6:first-child": {
            marginTop: 11,
          },
        },
      },
    },
    tableSubtitle: {
      marginTop: theme.spacing(2),
    },
    tableIcon: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      marginBottom: 0,
      "& div": {
        backgroundColor: theme.palette.secondary.main,
        width: 40,
        height: 40,
        borderRadius: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 290,
      },
      "& svg": {
        color: theme.palette.common.white,
        width: 24,
        height: 24,
      },
    },
    addButton: {
      position: "absolute",
      top: 10,
      right: 10,
      zIndex: 2000,
    },
    actionLink: {
      textDecoration: "none",
      "&:focus, &:hover, &:visited, &:link, &:active": {
        textDecoration: "none",
      },
      color: "unset",
    },
    companyName: {
      color: theme.palette.primary.dark,
    },
  })
);

export const tableStyle: React.CSSProperties = {
  border: "none",
  boxShadow: "none",
};

export const searchFieldStyle = (theme: Theme): React.CSSProperties => ({
  backgroundColor: theme.colors.lightGrey,
  height: 50,
  width: 351,
});

export default useStyles;

import { Theme } from "@material-ui/core";

const styles = (theme: Theme) => ({
  frontappButton: {
    position: "fixed" as "fixed",
    color: theme.palette.common.white,
    right: 0,
    top: 0,
    paddingRight: 9,
    paddingTop: 5,
    zIndex: 1199,
  },
});

export default styles;

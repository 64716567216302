import { Container, Grid } from "@material-ui/core";
import React from "react";
import useStyles from "./layoutNonMemberIntegration.style";

const LayoutNonMemberIntegration = (props: any) => {
  const classes = useStyles();

  return (
    <Container maxWidth={false} disableGutters={true} className={classes.root}>
      <Grid container direction="column">
        <Grid item className={classes.rightHalf}>
          {props.children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default LayoutNonMemberIntegration;

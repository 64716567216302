import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pvdsAutocomplete: {
      width: 424,
    },
    pvdsGrid: {
      marginTop: theme.spacing(8),
    },
    limitTag: {
      backgroundColor: "#EFF1F5",
      height: 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: 30,
    },
  })
);

export default useStyles;

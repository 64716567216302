import theme from "../Theme";

export const statusToStyle = (status: string): any => {
  if (!status) return theme.inventoryTracker.statusStyles.default;
  const statusNameNormalized = status.split(" ").join("").replace("-", "");
  const color = theme.inventoryTracker.statusStyles[statusNameNormalized];
  return color || theme.inventoryTracker.statusStyles.default;
};

export const internalToDisplayStatus = (status: string) => {
  switch (status) {
    case "New Transaction":
      return "Ordered";
    case "Approved":
      return "Dealer Inventory";
    case "Booked":
      return "Sold to Customer";
    case "Paid Off":
      return "Paid Off";
    default:
      return "N/A";
  }
};

export const estFundDateToDealerNotes = (value: string) => {
  switch (value) {
    case "dead":
      return "sold";
    case "stalled":
      return "under repair";
    case "active":
      return "active";
    case "next month":
      return "out on demo";
    case "this month":
      return "out on rent";
    default:
      return "N/A";
  }
};

export const equipmentNameToImage = (value: string) => {
  switch (value) {
    case "SWE80UF":
      return "/inv-SWE90UF.png";
    default:
      return `/inv-${value}.png`;
  }
};

import React from "react";
import {
  Box,
  Typography,
  Grid,
  withStyles,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import classnames from "classnames";
import { getMyInventory } from "../../Api";
import styles from "./myInventory.style";
import { CompanyLogo } from "../Common";
import { equipmentNameToImage } from "../../Helpers/inventory-tracker";
import { numberToShortFormat } from "../../Helpers/formatters";

interface Tile {
  title: string;
  active: string;
  sold: string;
  image: string;
  cssClass?: string;
}

interface MyInventoryProps {
  menuExpanded: boolean;
  classes?: any;
}

interface MyInventoryItem {
  label: string;
  active: number;
  sold: number;
}

interface MyInventoryState {
  data: Array<MyInventoryItem>;
  isLoading: boolean;
  isError: boolean;
}

class MyInventory extends React.Component<MyInventoryProps, MyInventoryState> {
  constructor(props: MyInventoryProps) {
    super(props);
    this.state = {
      data: [],
      isLoading: false,
      isError: false,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    getMyInventory(
      (data: Array<MyInventoryItem>) => {
        this.setState({ data: data, isLoading: false, isError: false });
      },
      (err) => {
        this.setState({
          data: [],
          isLoading: false,
          isError: true,
        });
      }
    );
  }

  render() {
    const { classes } = this.props;

    const renderTile = (tile: Tile) => (
      <Grid item className={classes.tileContainer}>
        <Box
          className={classnames(classes.tile, classes.tileBorderLight)}
          boxShadow={10}
        >
          <Avatar
            src={tile.image}
            className={classnames(classes.tileAvatar, tile.cssClass)}
            alt={tile.title}
            variant="square"
          >
            <img src="/inv-no-product-image.jpg" alt={tile.title}></img>
          </Avatar>
          <Box className={classes.tileText}>
            <Typography variant="h2">{tile.title}</Typography>
            <Typography variant="h2" className={classes.tileNumber}>
              {this.state.isLoading && (
                <CircularProgress
                  size={24}
                  thickness={1}
                  className={classes.progress}
                ></CircularProgress>
              )}
              {!this.state.isLoading && (
                <>
                  <span className={classes.tileNumberDark}>
                    Active {tile.active}
                  </span>
                  <br></br>
                  <span className={classes.tileNumberLight}>
                    Sold {tile.sold}
                  </span>
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </Grid>
    );

    const createTile = (index: number) => {
      if (this.state.data.length <= index)
        return (
          <div className={classnames(classes.tileContainer, classes.spacer)}>
            <div className={classes.tile}></div>
          </div>
        );
      const item = this.state.data[index];
      return item.label === "Inventory Line"
        ? renderTile({
            title: item.label,
            active: "$" + numberToShortFormat(item.active),
            sold: "$" + numberToShortFormat(item.sold),
            image: "/sunward-inventory-tracker.png",
            cssClass: classes.tileAvatarInventoryLine,
          })
        : renderTile({
            title: item.label,
            active: Intl.NumberFormat("en-US").format(item.active),
            sold: Intl.NumberFormat("en-US").format(item.sold),
            image: equipmentNameToImage(item.label),
          });
    };

    return (
      <Box
        className={classnames({
          [classes.expandedMenu]: this.props.menuExpanded,
          [classes.collapsedMenu]: !this.props.menuExpanded,
        })}
      >
        <CompanyLogo menuExpanded={this.props.menuExpanded}></CompanyLogo>
        <Box>
          <Typography className={classes.headerText} variant="h1">
            My Inventory
          </Typography>
          <Typography variant="h6" className={classes.headerSubtitle}>
            This dashboard houses equipment inventory that is actively for sale
            as well as those items that have been sold.
          </Typography>
        </Box>
        <Grid container className={classes.tiles} direction="column">
          {Array.from(Array(Math.ceil(this.state.data.length / 4)).keys()).map(
            (index) => (
              <Grid
                container
                className={classes.tilesGroup}
                direction="row"
                key={index}
              >
                <Grid item>
                  <Grid
                    container
                    className={classes.tilesGroup}
                    direction="row"
                  >
                    {createTile(4 * index)}
                    {createTile(4 * index + 1)}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    className={classes.tilesGroup}
                    direction="row"
                  >
                    {createTile(4 * index + 2)}
                    {createTile(4 * index + 3)}
                  </Grid>
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(MyInventory);

const compareStrings = (first: string, second: string) => {
  if (!first && !second) return 0;
  if (!first) return -1;
  if (!second) return 1;

  var f = first.toUpperCase(); // ignore upper and lowercase
  var s = second.toUpperCase();
  if (f < s) {
    return -1;
  }
  if (f > s) {
    return 1;
  }
  // equal
  return 0;
};

const compareMilestones = (first: any, second: any) => {
  if ((!first && !second) || (!first.date && !second.date)) return 0;
  if (!first || !first.date) return -1;
  if (!second || !second.date) return 1;

  if (first.date < second.date) {
    return -1;
  }
  if (first.date > second.date) {
    return 1;
  }
  // equal
  return 0;
};

export { compareStrings, compareMilestones };

import React from "react";
import { getChatHash } from "../../Api";
import {
  Badge,
  IconButton,
  withStyles,
  withWidth,
  isWidthDown,
} from "@material-ui/core";
import classnames from "classnames";
import styles from "./frontapp.style";
import ChatIcon from "@material-ui/icons/Chat";
import theme from "../../Theme";

interface FrontappProps {
  emailAddress: string | undefined;
  classes?: any;
  width?: any;
}

interface FrontappState {
  unreadCount: number;
  useDefaultLauncher: boolean;
  isSmallScreen: boolean;
  chatHash: string | null;
}

const StyledBadge = withStyles(() => ({
  badge: {
    right: 2,
    top: 25,
    border: `2px solid ${theme.colors.darkBlue}`,
    padding: "0 4px",
  },
}))(Badge);

class Frontapp extends React.Component<FrontappProps, FrontappState> {
  constructor(props: FrontappProps) {
    super(props);
    this.state = {
      unreadCount: 0,
      useDefaultLauncher: true,
      isSmallScreen: isWidthDown("sm", props.width),
      chatHash: null,
    };
  }

  setUnreadCount = (unread: any) => {
    this.setState({ unreadCount: unread.unread_count });
  };

  componentDidUpdate(prevProps: FrontappProps) {
    if (prevProps.width !== this.props.width) {
      const isSmallWidth = isWidthDown("sm", this.props.width);
      if (isSmallWidth !== this.state.isSmallScreen) {
        this.setState({ isSmallScreen: isSmallWidth });
        window.FrontChat("shutdown", "");
        this.initChat(this.state.chatHash as string, !isSmallWidth);
      }
    }
  }

  initChat(userHash: string, useDefaultLauncher: boolean) {
    if (window.FrontChat) {
      window.FrontChat("init", {
        chatId: "11f51337d484243e666d17348adf98a1",
        useDefaultLauncher: useDefaultLauncher,
        email: this.props.emailAddress,
        userHash: userHash,
      });
    } else {
      setTimeout(() => {
        this.initChat(userHash, useDefaultLauncher);
      }, 100);
    }
  }

  setup(userHash: string) {
    var s1 = document.createElement("script");
    s1.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
    s1.async = true;
    document.body.appendChild(s1);

    this.initChat(userHash, !this.state.isSmallScreen);
  }

  componentDidMount() {
    getChatHash(
      (data) => {
        this.setup(data);
        this.setState({ chatHash: data });
      },
      (err) => {}
    );
  }

  render() {
    const { classes } = this.props;

    return this.state.isSmallScreen ? (
      <IconButton
        onClick={() => {
          window.FrontChat("show", "");
        }}
        className={classnames(classes.frontappButton)}
      >
        <StyledBadge badgeContent={this.state.unreadCount} color="secondary">
          <ChatIcon fontSize="large" />
        </StyledBadge>
      </IconButton>
    ) : (
      <></>
    );
  }
}

export default withWidth()(withStyles(styles)(Frontapp));

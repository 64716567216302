import { Column } from "material-table";
import {
  Data,
  getCompanyIdDisplayValue,
  getFinanceManager,
  internalToDisplayStatus,
  statusToStyle,
} from "../../../Helpers/deal-pipelines";
import { compareStrings } from "../../../Helpers/utils";
import { renderAvatar, renderChip } from "../common/columns";

const columns: Array<Column<Data>> = [
  {
    title: "Comp ID",
    render: getCompanyIdDisplayValue,
    customFilterAndSearch: (filter: any, rowData: Data) =>
      getCompanyIdDisplayValue(rowData).indexOf(filter.toLowerCase()) >= 0,
    customSort: (row1, row2) =>
      compareStrings(
        getCompanyIdDisplayValue(row1),
        getCompanyIdDisplayValue(row2)
      ),
    removable: false,
    width: 150,
  },
  {
    field: "compName",
    title: "Company Name",
    removable: false,
    width: 270,
  },
  { field: "leadDate", title: "Created", type: "date", width: 95 },
  { field: "primaryContact", title: "Contact Name", hidden: true, width: 110 },
  { field: "leadType", title: "Comp Type", width: 90 },
  {
    title: "Current Status",
    render: (rowData) =>
      renderChip(rowData.leadStatus, statusToStyle, (s: string) =>
        internalToDisplayStatus(s, "lead")
      ),
    customFilterAndSearch: (filter: any, rowData: Data) => {
      const value = internalToDisplayStatus(rowData.leadStatus, "lead") || "";
      return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    },
    customSort: (row1, row2) =>
      compareStrings(
        internalToDisplayStatus(row1.leadStatus, "lead"),
        internalToDisplayStatus(row2.leadStatus, "lead")
      ),
    width: 190,
  },
  {
    title: "Finance Manager",
    render: (rowData) => {
      return renderAvatar(getFinanceManager(rowData));
    },
    customFilterAndSearch: (filter: any, rowData: Data) => {
      const value = getFinanceManager(rowData) || "";
      return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    },
    customSort: (row1, row2) =>
      compareStrings(getFinanceManager(row1), getFinanceManager(row2)),
    width: 120,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "pid",
    title: "PID",
    hidden: true,
    width: 110,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "vendorName",
    title: "Lead Source (VID)",
    width: 130,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "did",
    title: "DID",
    hidden: true,
    width: 110,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "rid",
    title: "RID",
    hidden: true,
    width: 110,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "eid",
    title: "EID",
    hidden: true,
    width: 110,
    cellStyle: { overflowWrap: "break-word" },
  },
  { field: "lastTouch", title: "Last Touch", type: "date", width: 90 },
  { field: "nextTouch", title: "Next Touch", type: "date", width: 90 },
  {
    field: "vendorSalesRep",
    title: "Vendor Sales Rep",
    hidden: true,
    width: 110,
  },
  {
    field: "lastUpdated",
    title: "Last Updated",
    hidden: true,
    searchable: false,
    defaultSort: "desc",
    type: "datetime",
    width: 100,
  },
];
const columnsPVD: Array<Column<Data>> = [
  {
    field: "pid",
    title: "PID",
    hidden: true,
    width: 110,
  },
  {
    field: "did",
    title: "DID",
    hidden: true,
    width: 110,
  },
  {
    field: "rid",
    title: "RID",
    hidden: true,
    width: 110,
  },
  {
    field: "eid",
    title: "EID",
    hidden: true,
    width: 110,
  },
];

export { columns, columnsPVD };

import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { UploadInstance } from "../../../../Helpers/types";
import {
  postCreateUploadInstance,
  deleteUploadInstance,
} from "../../../../Api";
import Upload from "./Upload";
import ThankYou from "./ThankYou";
import auth from "../../../../Services/auth";

const getUploadStorageKey = (activityId: string, activityType: string) => {
  const userId = auth.getUser().id;
  return `upld-${userId}-${activityType}${activityId}`;
};

const storeUploadNotes = (
  activityId: string,
  activityType: string,
  notes: Array<string>
): void => {
  localStorage.setItem(
    getUploadStorageKey(activityId, activityType),
    JSON.stringify(notes)
  );
};

const getStoredUploadNotes = (
  activityId: string,
  activityType: string
): Array<string> | null => {
  const notesRaw = localStorage.getItem(
    getUploadStorageKey(activityId, activityType)
  );
  return notesRaw && JSON.parse(notesRaw);
};

const removeStoredUploadNotes = (
  activityId: string,
  activityType: string
): void => {
  localStorage.removeItem(getUploadStorageKey(activityId, activityType));
};

interface DocUploaderProps {
  activityId: string;
  activityType: "lead" | "transaction";
  taycorRep: any;
}

function DocUploader(props: DocUploaderProps) {
  const { activityId, activityType, taycorRep } = props;
  const [uploadInstance, setUploadInstance] = useState<UploadInstance | null>(
    null
  );
  const [
    previousUploadNotes,
    setPreviousUploadNotes,
  ] = useState<Array<string> | null>(null);

  useEffect(() => {
    const existingUploadNotes = getStoredUploadNotes(activityId, activityType);
    if (existingUploadNotes && Array.isArray(existingUploadNotes)) {
      setUploadInstance(null);
      setPreviousUploadNotes(existingUploadNotes);
    } else {
      setPreviousUploadNotes(null);
      postCreateUploadInstance(
        activityId,
        activityType,
        (data) => {
          setUploadInstance(data);
        },
        () => {
          setUploadInstance(null);
        }
      );
    }
  }, [activityId, activityType]);

  const onUploadDone = (notes: Array<string>) => {
    storeUploadNotes(activityId, activityType, notes);
    deleteUploadInstance(
      activityId,
      activityType,
      () => {},
      () => {}
    );
    setUploadInstance(null);
    setPreviousUploadNotes(notes);
  };
  const onNewUpload = () => {
    removeStoredUploadNotes(activityId, activityType);
    setPreviousUploadNotes(null);
    postCreateUploadInstance(
      activityId,
      activityType,
      (data) => {
        setUploadInstance(data);
      },
      () => {
        setUploadInstance(null);
      }
    );
  };

  return (
    <>
      {!uploadInstance && !previousUploadNotes && (
        <CircularProgress></CircularProgress>
      )}
      {uploadInstance && (
        <Upload
          uploadInstance={uploadInstance}
          onUploadDone={(_uploadId, notes) => onUploadDone(notes)}
        ></Upload>
      )}
      {previousUploadNotes && (
        <ThankYou
          uploadNotes={
            previousUploadNotes.filter((note) => note !== null) || []
          }
          taycorRep={taycorRep}
          onNewUpload={onNewUpload}
        ></ThankYou>
      )}
    </>
  );
}

export default DocUploader;

import { Vendor } from "../Helpers/types";
import { compareStrings } from "./utils";

export const expandUserLeadSources = (
  values: string[],
  vendors: Vendor[],
  previousValues: string[] = []
) => {
  if (!values || !vendors) return values;

  const valuesSet = new Set(values);
  const removedLeadSources = previousValues.filter((v) => !valuesSet.has(v));
  const leadSources: Set<string> = new Set();
  valuesSet.forEach((ls) => {
    // skip removed lead sources
    if (
      removedLeadSources.some(
        (r) => r === ls || getVendorName(r) === ls || r === getVendorName(ls)
      )
    ) {
      return;
    }
    // include all vendors that start with the vendor name and a dot (vendor wildcard)
    leadSources.add(ls);
    vendors
      .filter((v) => v.name.indexOf(`${ls}.`) === 0)
      .map((v) => {
        leadSources.add(v.name);
        return v.name;
      });
  });

  return Array.from(leadSources);
};

export const collapseUserLeadSources = (values: string[]) => {
  if (!values) return values;

  const leadSources: Set<string> = new Set();
  // leave only the vendor name when all ref dealers are selected
  values.sort(compareStrings).map((ls) => {
    const vendor = getVendorName(ls);
    if (!leadSources.has(vendor)) {
      leadSources.add(ls);
    }
    return ls;
  });

  return Array.from(leadSources);
};

const getVendorName = (leadSource: string) => {
  // find a dot, except the first one; if we do, it means this vendor name includes the ref dealer
  const dotIndex = leadSource.indexOf(".", 1);
  return dotIndex > 0 ? leadSource.substr(0, dotIndex) : leadSource;
};

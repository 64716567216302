import {
  Box,
  Checkbox,
  Divider,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab";
import { Button, Search } from "@taycor/ui-library";
import React, { useEffect, useRef, useState } from "react";
import { ParentVendorDealer } from "../../Helpers/types";
import useStyles from "./pvdsAutocomplete.style.ts";

interface PvdsAutocompleteProps {
  pvdIdsArray: ParentVendorDealer[];
  openInitital: boolean;
  anchorEl: any;
  handleSelectedPVDIds: (selected: ParentVendorDealer[]) => void;
  filtering: boolean;
  chipping: boolean;
  selectedCome?: ParentVendorDealer[];
  handleClose?: any;
  userPVDIds?: ParentVendorDealer[];
  type?: string;
  brandIds?: string[];
}

const PvdsAutocomplete = ({
  pvdIdsArray,
  openInitital,
  anchorEl,
  handleSelectedPVDIds,
  filtering,
  chipping,
  selectedCome,
  handleClose,
  userPVDIds,
  type,
  brandIds,
}: PvdsAutocompleteProps) => {
  const classes = useStyles();

  const [pvdIds, setPvdIds] = useState<ParentVendorDealer[]>([]);
  const [selectedPvdIds, setSelectedPvdIds] = useState<ParentVendorDealer[]>(
    []
  );
  const selectedPvdIdsRef = useRef(selectedPvdIds);
  const [open, setOpen] = useState<boolean>(false);

  const [checkedPvdIds, setCheckhedPvdIds] = useState<boolean>(false);

  useEffect(() => {
    setPvdIds(pvdIdsArray);
  }, [pvdIdsArray]);

  useEffect(() => {
    if (!filtering && !chipping) {
      handleSelectedPVDIds(selectedPvdIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPvdIds]);

  useEffect(() => {
    setOpen(openInitital);
  }, [openInitital]);
  useEffect(() => {
    if (selectedCome) {
      setSelectedPvdIds(selectedCome);
    }
  }, [selectedCome]);
  useEffect(() => {
    if (userPVDIds) {
      const userPVDIdsArray = userPVDIds.map((s) => s.id);
      const filteredPVDIds = pvdIds.filter((x) =>
        userPVDIdsArray.includes(x.id)
      );
      setSelectedPvdIds(filteredPVDIds);
    } else {
      if (brandIds) {
        const filteredPVDIds = selectedPvdIdsRef.current.filter((p) =>
          brandIds?.includes(p.brandId)
        );
        setSelectedPvdIds(filteredPVDIds);
      }
    }
  }, [userPVDIds, pvdIds, brandIds]);

  useEffect(() => {
    selectedPvdIdsRef.current = selectedPvdIds;
  }, [selectedPvdIds]);

  const autocompleteId = () => {
    switch (type) {
      case "pids":
        return "pidsaccess";
      case "vids":
        return "vidsaccess";
      case "dids":
        return "didsaccess";
      case "rids":
        return "ridsaccess";
    }
  };
  return (
    <>
      <Popover
        onBackdropClick={() => handleClose()}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={
          filtering
            ? { vertical: "top", horizontal: "right" }
            : { vertical: "bottom", horizontal: "left" }
        }
      >
        <Box className={classes.pvdsPopoverBox}>
          <Autocomplete
            open={true}
            multiple
            value={selectedPvdIds}
            id={autocompleteId()}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(
              _: any,
              newValue: any,
              reason: AutocompleteChangeReason
            ) => {
              if (reason === "select-option") {
                setSelectedPvdIds(newValue);
              } else {
                if (reason === "remove-option") {
                  setCheckhedPvdIds(false);
                  setSelectedPvdIds(newValue);
                }
              }
            }}
            classes={{
              paper: classes.pvdsAutocomplete,
              option: classes.pvdsMenuOption,
              listbox: classes.pvdsMenu,
            }}
            disableCloseOnSelect
            disablePortal={true}
            renderTags={() => null}
            noOptionsText="No results."
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  color="primary"
                  checked={
                    chipping
                      ? selectedPvdIds.some(
                          (element) => element.name === option.name
                        )
                      : selectedCome === []
                      ? false
                      : selected || checkedPvdIds
                  }
                />
                <Typography>{option.name}</Typography>
              </React.Fragment>
            )}
            options={pvdIds.sort((a, b) => a.name.localeCompare(b.name))}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                className={classes.pvdsTextField}
                {...params}
                placeholder="Search"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <Search></Search>,
                  endAdornment: <></>,
                  className: classes.pvdsSearch,
                }}
              />
            )}
          />

          <Box className={classes.pvdsBox}>
            <Divider />
            <Box className={classes.pvdsButtonBox}>
              <Box className={classes.pvdsSelectClearBox}>
                <Button
                  variant="text"
                  label="Select all"
                  classes={{ root: classes.pvdsSelectAllButton }}
                  action={(e: any) => {
                    setSelectedPvdIds(pvdIds);
                    setCheckhedPvdIds(true);
                  }}
                ></Button>
                {selectedPvdIds.length > 0 && (
                  <Button
                    variant="text"
                    label="Clear selection"
                    classes={{ root: classes.pvdssClearSelectionButton }}
                    action={(e: any) => {
                      setSelectedPvdIds([]);
                      setCheckhedPvdIds(false);
                    }}
                  ></Button>
                )}
              </Box>
              <Box className={classes.pvdsAddSaveFilterBox}>
                {filtering && selectedPvdIds.length > 0 && (
                  <Button
                    // eslint-disable-next-line react/style-prop-object
                    style="rectangle"
                    color="secondary"
                    variant="contained"
                    label="Add filter"
                    classes={{ root: classes.pvdAddFilterButton }}
                    action={(e: any) => {
                      handleSelectedPVDIds(selectedPvdIds);
                    }}
                  ></Button>
                )}
                {!filtering && chipping && (
                  <Button
                    // eslint-disable-next-line react/style-prop-object
                    style="rectangle"
                    color="secondary"
                    variant="contained"
                    label="Save"
                    classes={{ root: classes.pvdAddFilterButton }}
                    action={(e: any) => {
                      handleSelectedPVDIds(selectedPvdIds);
                    }}
                  ></Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default PvdsAutocomplete;

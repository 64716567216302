import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { formField } from "../../../Theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 130,
      width: `calc(100vw - ${theme.layout.leftDrawerWidth}px - 104px)`,
      [theme.breakpoints.down("md")]: {
        width: `calc(100vw - 102px)`,
      },
      marginLeft: theme.spacing(12),
      marginRight: theme.spacing(14),
    },
    label: {
      marginTop: 10,
    },
    headerSubtitle: {
      marginTop: theme.spacing(2),
    },
    addIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
      height: 75,
      marginBottom: -36,
      "& div": {
        backgroundColor: theme.palette.secondary.main,
        width: 40,
        height: 40,
        borderRadius: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& svg": {
        color: theme.palette.common.white,
        width: 24,
        height: 24,
      },
    },
    formPVD: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      boxShadow: "0px 6px 20px 0 rgba(193, 207, 217, 0.2)",
      [theme.breakpoints.down("md")]: {
        boxShadow: "0px 6px 0 0 rgba(193, 207, 217, 0.2)",
      },
    },
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "0px 6px 20px 0 rgba(193, 207, 217, 0.2)",
      [theme.breakpoints.down("md")]: {
        boxShadow: "0px 6px 0 0 rgba(193, 207, 217, 0.2)",
      },
      paddingBottom: 90,
    },

    grid: {
      marginTop: theme.spacing(8),
      maxWidth: 1180,
    },
    gridItemPVD: {
      marginTop: theme.spacing(4),

      [theme.breakpoints.down("md")]: {
        padding: "12px 70px 12px 0",
      },
    },
    gridItem: {
      padding: "12px 70px 12px 70px",
      [theme.breakpoints.down("md")]: {
        padding: "12px 70px 12px 0",
      },
    },

    formFieldSmall: {
      marginTop: 0,
      width: 312,
    },
    formFieldPVD: {
      marginTop: 0,
      width: 424,
      ...formField,
    },
    formField: {
      width: 450,
      ...formField,
    },
    legacyLeadSource: {
      marginTop: 0,
      width: 424,
      overflow: "scroll",
      ...formField,
    },
    upload: {
      width: 450,
      marginTop: 23,
      display: "flex",
      justifyContent: "space-between",
      textTransform: "initial",
      fontSize: 14,
      fontWeight: "normal",
      color: "rgba(0, 0, 0, 0.54)",
      paddingLeft: 0,
      borderBottom: `3px solid ${theme.colors.lightGrey}`,
    },
    isActive: {
      marginTop: theme.spacing(4),
    },
    helperText: {
      color: theme.colors.mediumGrey,
    },
    companyLogoHelperText: {
      marginTop: 11,
    },
    actions: {},
    submitPVD: {
      marginTop: theme.spacing(4),
      width: 128,
      height: 48,
      textTransform: "initial",
      color: theme.palette.common.white,
    },
    submit: {
      marginTop: 87,
      width: 338,
      marginLeft: 10,
      textTransform: "initial",
      color: theme.palette.common.white,
    },
    backLink: {
      textDecoration: "none",
    },
    alert: {
      marginTop: theme.spacing(4),
      width: 450,
    },
    visible: {
      display: "block",
    },
    hidden: {
      display: "none",
    },
    companyLogoAvatar: {
      height: 32,
      width: 250,
      justifyContent: "left",
    },
    companyLogoAvatarImg: {
      height: "inherit",
      width: "unset",
      maxHeight: 32,
      maxWidth: 250,
    },
    logoFormField: {
      position: "relative",
    },
    removeLogoLink: {
      position: "absolute",
      top: 12,
      right: 38,
      cursor: "pointer",
    },
    sectionHeader: {
      marginTop: theme.spacing(6),
      fontWeight: 600,
      fontSize: 18,
    },
    sectionContainer: {
      marginTop: theme.spacing(6),
    },
    uploadBox: {
      display: "flex",
      flexDirection: "row",
    },
    uploadLinkText: {
      fontWeight: 600,
      color: "#14679F",
    },
    brandIdsSpecialMenuItem: {
      fontWeight: 600,
      backgroundColor: "#8dc2f7",
      "&.Mui-selected": {
        backgroundColor: "#52a4f7",
        "&:hover": {
          backgroundColor: "#68b0f7",
        },
      },
      "&:hover": {
        backgroundColor: "#68b0f7",
      },
    },
  })
);

export default useStyles;

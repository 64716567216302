import axios from "axios";
import config from "../config";
import logger from "../Helpers/logger";
import auth from "../Services/auth";

const getAuthHeader = () => {
  return { Authorization: "Bearer " + auth.getAccessToken() };
};

const get = (
  url: string,
  success: (data: any) => void,
  error: (err: any) => void,
  fakeData?: any
) => {
  const endpoint = config.api.url + url;
  if (config.api.fakeIt) {
    setTimeout(() => success(fakeData || []), 500);
  } else {
    axios
      .get(endpoint, { headers: getAuthHeader() })
      .then((response) => {
        success(response.data);
      })
      .catch((err) => {
        logger.logError(err);
        error(err);
      });
  }
};

const post = (
  url: string,
  data: any,
  success: (data: any) => void,
  error: (err: any) => void,
  fakeData?: any
) => {
  const endpoint = config.api.url + url;
  if (config.api.fakeIt) {
    setTimeout(() => success(fakeData || { succes: true }), 500);
  } else {
    axios
      .post(endpoint, data, { headers: getAuthHeader() })
      .then((response) => success(response.data))
      .catch((err) => {
        logger.logError(err);
        error(err);
      });
  }
};

const postWithFiles = (
  url: string,
  data: any,
  files: any,
  success: (data: any) => void,
  error: (err: any) => void,
  fakeData?: any
) => {
  const endpoint = config.api.url + url;
  if (config.api.fakeIt) {
    setTimeout(() => success(fakeData || { succes: true }), 500);
  } else {
    const formData = new FormData();
    Object.keys(data).map((key) =>
      Array.isArray(data[key])
        ? data[key].map((value: any, index: number) =>
            formData.append(`${key}[${index}]`, value)
          )
        : formData.append(key, data[key] === null ? "" : data[key])
    );
    Object.keys(files).map((key) => formData.append(key, files[key]));
    axios
      .post(endpoint, formData, {
        headers: Object.assign(getAuthHeader(), {
          "Content-Type": "multipart/form-data",
        }),
      })
      .then((response) => success(response.data))
      .catch((err) => {
        logger.logError(err);
        error(err);
      });
  }
};

const patchWithFiles = (
  url: string,
  data: any,
  files: any,
  success: (data: any) => void,
  error: (err: any) => void,
  fakeData?: any
) => {
  const endpoint = config.api.url + url;
  if (config.api.fakeIt) {
    setTimeout(() => success(fakeData || { succes: true }), 500);
  } else {
    const formData = new FormData();
    Object.keys(data).map((key) =>
      Array.isArray(data[key])
        ? data[key].map((value: any, index: number) =>
            formData.append(`${key}[${index}]`, value)
          )
        : formData.append(key, data[key] === null ? "" : data[key])
    );
    Object.keys(files).map((key) => formData.append(key, files[key]));

    axios
      .patch(endpoint, formData, {
        headers: Object.assign(getAuthHeader(), {
          "Content-Type": "multipart/form-data",
        }),
      })
      .then((response) => success(response.data))
      .catch((err) => {
        logger.logError(err);
        error(err);
      });
  }
};

const del = (
  url: string,
  success: (data: any) => void,
  error: (err: any) => void,
  fakeData?: any
) => {
  const endpoint = config.api.url + url;
  if (config.api.fakeIt) {
    setTimeout(() => success(fakeData || { succes: true }), 500);
  } else {
    axios
      .delete(endpoint, { headers: getAuthHeader() })
      .then((response) => success(response.data))
      .catch((err) => {
        logger.logError(err);
        error(err);
      });
  }
};

export { get, post, postWithFiles, patchWithFiles, del };

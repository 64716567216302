import developConfig from "./Config/config.develop";
import localConfig from "./Config/config.local";
import messiConfig from "./Config/config.messi";
import prepProdConfig from "./Config/config.pre-prod";
import prodConfig from "./Config/config.prod";
import stagingConfig from "./Config/config.staging";
import unstableConfig from "./Config/config.unstable";

interface ConfigPortalApi {
  url: string;
  fakeIt?: boolean;
}
interface ConfigUploadApi {
  url: string;
  fakeIt?: boolean;
  uploadDocumentsCode?: string;
}
interface ConfigGoogleAnalytics {
  id: string;
  enabled?: boolean;
}
interface ConfigAppInsights {
  key?: string;
  disabled?: boolean;
}
interface Config {
  api: ConfigPortalApi;
  uploadApi: ConfigUploadApi;
  ga: ConfigGoogleAnalytics;
  ai: ConfigAppInsights;
  pvd: any;
  midpointPortal?: string;
}

const envConfig = ((): Partial<Config> => {
  const currentEnv = process.env.REACT_APP_ENVIRONMENT || "local";
  switch (currentEnv.toLowerCase()) {
    case "messi":
      return messiConfig;
    case "unstable":
      return unstableConfig;
    case "develop":
      return developConfig;
    case "staging":
      return stagingConfig;
    case "pre_prod":
      return prepProdConfig;
    case "prod":
      return prodConfig;

    default:
      return localConfig;
  }
})();

const config: Config = {
  api: {
    ...{
      url: "http://localhost:5000/api/v1",
      fakeIt: false,
    },
    ...envConfig.api,
  },
  ai: {
    ...{
      key: "bf7acb14-f9ee-45c6-87f5-d36cd2d679bf",
      disabled: false,
    },
    ...envConfig.ai,
  },
  ga: {
    ...{
      id: "G-QHZ9Y5NTWN",
      enabled: true,
    },
    ...envConfig.ga,
  },
  uploadApi: {
    ...{
      url: "http://localhost:7071/api/v1",
      uploadDocumentsCode: process.env.REACT_APP_UPLOAD_DOCUMENTS_CODE,
    },
    ...envConfig.uploadApi,
  },
  pvd: {
    ...{
      enabled: false,
    },
    ...envConfig.pvd,
  },
  midpointPortal: envConfig.midpointPortal,
};

export default config;

import React from "react";
import { getFundedTransactions } from "../../../Api";
import withData from "../../Data/withData";
import BaseView from "../common/BaseView";
import { columns, columnsPVD } from "./config";

function View(props: any) {
  return (
    <BaseView
      columns={columns}
      columnsPVD={columnsPVD}
      title="Funded Transactions"
      subtitle="These are companies that have successfully completed the journey and have fully funded."
      {...props}
    ></BaseView>
  );
}

export default withData(View, getFundedTransactions);

import React from "react";
import { TextField, TextFieldProps, Checkbox } from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteProps,
} from "@material-ui/lab";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { FieldProps } from "formik";
import { fieldToTextField } from "formik-material-ui";
import useStyles from "./formikAutocomplete.style";

const AnyAutocomplete = Autocomplete as any;

export interface FormikAutocompleteProps<
  V extends any = any,
  FormValues extends any = any,
  Multiple extends boolean | undefined = any,
  DisableClearable extends boolean | undefined = any,
  FreeSolo extends boolean | undefined = any
> extends FieldProps<V, FormValues>,
    AutocompleteProps<V, Multiple, DisableClearable, FreeSolo> {
  textFieldProps: TextFieldProps;
}

const noOp = () => {};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FormikAutocomplete = <V extends any = any, FormValues extends any = any>({
  textFieldProps,
  ...props
}: FormikAutocompleteProps<V, FormValues>) => {
  const classes = useStyles();
  const {
    form: { setTouched, setFieldValue, touched },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props as any);
  const { name } = field;
  const onInputChangeDefault = props.onInputChange ?? noOp;
  const onInputChange = !props.freeSolo
    ? props.onInputChange
    : (
        event: React.ChangeEvent<{}>,
        value: string,
        reason: AutocompleteInputChangeReason
      ) => {
        setFieldValue(name!, value);
        onInputChangeDefault(event, value, reason);
      };
  const onChange = (event: any, value: any, reason: any) => {
    props.onChange
      ? props.onChange(event, value, reason)
      : setFieldValue(name!, value);
  };

  return (
    <AnyAutocomplete
      {...props}
      {...field}
      onChange={onChange}
      onInputChange={onInputChange}
      onBlur={() => setTouched({ ...touched, [name!]: true })}
      multiple
      disableCloseOnSelect
      classes={{
        option: classes.option,
      }}
      renderInput={(props: any) => (
        <TextField
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
        />
      )}
      renderOption={(option: any, s: any) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={s.selected}
          />
          {option}
        </>
      )}
    />
  );
};

export default FormikAutocomplete;

import React from "react";
import { getActiveLeads } from "../../../Api";
import withData from "../../Data/withData";
import BaseView from "../common/BaseView";
import { columns, columnsPVD } from "./config";

function View(props: any) {
  return (
    <BaseView
      columns={columns}
      columnsPVD={columnsPVD}
      title="Active Leads"
      subtitle="These are active companies that have not completed the
    application process."
      {...props}
    ></BaseView>
  );
}

export default withData(View, getActiveLeads);

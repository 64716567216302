import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {
  getCompanyIdDisplayValue,
  internalToDisplayStatus,
} from "../../../../Helpers/deal-pipelines";
import { ActivityType } from "../../../../Helpers/types";
import useStyles from "./company.style";
import TaycorRep from "./TaycorRep";

interface CompanyInfo {
  label: string;
  value: string | string[];
}

interface CompanyProps {
  company: any;
  activityType: ActivityType;
}

const Company = (props: CompanyProps) => {
  const classes = useStyles();
  const { company, activityType } = props;

  const generalData: Array<CompanyInfo> = [
    {
      label: "Company ID",
      value: getCompanyIdDisplayValue(company),
    },
    ...(props.activityType === "transaction"
      ? [{ label: "Account ID", value: company.accountId }]
      : []),
    {
      label: "Lead Date",
      value: company.leadDate
        ? new Date(company.leadDate).toLocaleDateString()
        : "",
    },
    {
      label: "Lead Source",
      value: company.vendorName,
    },
    {
      label: "Status",
      value: internalToDisplayStatus(
        company.status,
        activityType === "lead" ? "lead" : "transaction"
      ),
    },
    ...(props.activityType === "transaction"
      ? [
          {
            label: "Loan Amount",
            value: Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(company.amount || 0),
          },
        ]
      : []),
  ];

  const followupInformation: Array<CompanyInfo> = [
    {
      label: "Last Touch",
      value: company.lastTouch
        ? new Date(company.lastTouch).toLocaleDateString()
        : "",
    },
    {
      label: "Next Touch",
      value: company.nextTouch
        ? new Date(company.nextTouch).toLocaleDateString()
        : "",
    },
  ];

  const fundingDetails: Array<CompanyInfo> = [
    {
      label: "EID",
      value: company.eid,
    },
    {
      label: "LID",
      value: company.commonKey,
    },
    {
      label: "Stock Number",
      value: company.stockNumber,
    },
    {
      label: "Unit Id",
      value: company.unitId,
    },
    {
      label: "Title",
      value: company.title,
    },
    {
      label: "Price",
      value: company.price,
    },
    {
      label: "Year",
      value: company.year,
    },
    {
      label: "Make",
      value: company.make,
    },
    {
      label: "Model",
      value: company.model,
    },
    {
      label: "VIN",
      value: company.vin,
    },
  ];

  const nextStepsNeeded: Array<CompanyInfo> = [
    {
      label: "Vendor",
      value: (company.leadNoteToVendor || "").split("\\r\\n"),
    },
    {
      label: "Customer",
      value: (company.leadNoteToCustomer || "").split("\\r\\n"),
    },
  ];
  const displayNextStepsNeeded =
    activityType === "lead" &&
    (company.status === "Customer Action Required" ||
      company.status === "Need Information");

  const estFundDate = {
    label: "Est Fund Date",
    value: company.estFundDate,
  };

  activityType !== "lead" && followupInformation.push(estFundDate);

  const phonesKeys = Object.keys(company?.phones || {});
  const companySummaryData: Array<CompanyInfo> = [
    ...phonesKeys.map((key) => ({ label: company.phones[key], value: key })),
    { label: "Website", value: company.website },
    { label: "EIN", value: company.ein },
    { label: "Comp Type", value: company.compType },
  ];
  const getCompanyContactData = (contact: any) => [
    { label: "Email", value: contact.email || "" },
    {
      label: "Ownership",
      value: (contact.ownership || "").split(".")[0],
    },
  ];

  const renderDataTable = (
    data: Array<CompanyInfo>,
    title?: string | null,
    subtitle?: string | null,
    titleSpacing?: "small" | "medium" | "large",
    subtitleSize?: "small" | "default"
  ) => (
    <TableContainer
      component={Paper}
      className={classes.table}
      key={title || subtitle || "key"}
    >
      <Table>
        <TableHead>
          {title && (
            <TableRow
              className={classnames(classes.tableTitleRow, {
                [classes.tableTitleRowSpacingSm]: titleSpacing === "small",
                [classes.tableTitleRowSpacingMd]: titleSpacing === "medium",
                [classes.tableTitleRowSpacingLg]: titleSpacing === "large",
              })}
            >
              <TableCell colSpan={2} align="center">
                <Typography variant="h4">{title}</Typography>
              </TableCell>
            </TableRow>
          )}
          {subtitle && (
            <TableRow className={classes.tableSubtitleRow}>
              <TableCell colSpan={2} align="center">
                <Typography variant={subtitleSize === "small" ? "h6" : "h5"}>
                  {subtitle}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableHead>

        <TableBody>
          {data.map((item, index) => {
            const values = Array.isArray(item.value)
              ? item.value
              : [item.value];
            return (
              <TableRow key={index}>
                <TableCell align="right" className={classes.tableLabelCell}>
                  <Typography
                    variant="h6"
                    className={classes.tableLabelCellText}
                  >
                    {item.label}:
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableValueCell}>
                  {values.map((val, index) => (
                    <React.Fragment key={index}>
                      <Typography variant="h6">{val}</Typography>
                      {index < values.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {company?.taycorRep && (
        <TaycorRep taycorRep={company.taycorRep}></TaycorRep>
      )}
      {renderDataTable(
        generalData,
        company.compName,
        company.compAddress,
        "small",
        "small"
      )}
      {renderDataTable(fundingDetails, "FUNDING DETAILS", null, "large")}
      {renderDataTable(
        followupInformation,
        "FOLLOW-UP INFORMATION",
        null,
        "large"
      )}
      {displayNextStepsNeeded &&
        renderDataTable(nextStepsNeeded, "NEXT STEPS NEEDED", null, "large")}
      {renderDataTable(companySummaryData, "COMPANY SUMMARY", null, "large")}
      {company.contacts &&
        company.contacts.map(
          (contact: any, index: number) =>
            renderDataTable(
              getCompanyContactData(contact),
              index === 0 ? "CONTACT INFORMATION" : null,
              `${contact.firstName || ""} ${contact.lastName || ""}`
            ),
          "medium"
        )}
    </>
  );
};

export default Company;

import React from "react";
import { Button, Chip, Typography } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";

import useStyles from "./thankyou.style";
import TaycorRep from "./TaycorRep";

interface ThankYouProps {
  uploadNotes: Array<string>;
  taycorRep: any;
  onNewUpload: () => void;
}

function ThankYou(props: ThankYouProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Thank You
      </Typography>
      <Typography variant="h6" className={classes.subtitle}>
        Your documents have been successfully uploaded.
      </Typography>
      <Typography variant="h6" className={classes.documents}>
        Documents Sent
      </Typography>
      {props.uploadNotes.map((note, idx) => (
        <Chip
          key={idx}
          icon={<FileCopy></FileCopy>}
          label={note}
          color="primary"
          className={classes.document}
        ></Chip>
      ))}
      <Button
        variant="contained"
        type="button"
        color="primary"
        className={classes.button}
        onClick={props.onNewUpload}
      >
        Upload Other Documents
      </Button>
      {props.taycorRep && <TaycorRep taycorRep={props.taycorRep}></TaycorRep>}
    </div>
  );
}

export default ThankYou;

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pvdMainPopover: {
      display: "flex",
      flexDirection: "column",
      border: "1px solid #DADEE7",
      boxShadow: "0px 8px 24px -8px rgba(0, 0, 0, 0.32)",
      borderRadius: 4,
    },
    pvdAutocomplete: {
      width: "41%",
    },
    pvdOpen: {
      cursor: "pointer",
      whiteSpace: "normal",
      padding: "8px 24px",
      fontWeight: 600,
      backgroundColor: "#E6E9EF",
    },
    pvdNotOpen: {
      cursor: "pointer",
      whiteSpace: "normal",
      padding: "8px 24px",
      fontWeight: 600,
      backgroundColor: "white",
    },
    pvdActionsBox: {
      display: "flex",
    },
    pvdActionsButtons: {
      textTransform: "capitalize",
      "& span span svg path": {
        fill: "#707172",
      },
    },
    pvdChipBox: {
      paddingBottom: theme.spacing(1),

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    arrowAdornment: {
      width: 40,
      height: 10,
    },
  })
);

export default useStyles;

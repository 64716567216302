import { Column } from "material-table";
import {
  Data,
  internalToDisplayStatus,
  statusToStyle,
} from "../../../Helpers/deal-pipelines";
import { compareStrings } from "../../../Helpers/utils";
import { renderAvatar, renderChip } from "../common/columns";
import { centerAlignCellStyle } from "../common/columns.style";

const columns: Array<Column<Data>> = [
  { field: "accountId", title: "Account ID", removable: false, width: 100 },
  { field: "compName", title: "Company Name", removable: false, width: 270 },
  { field: "leadDate", title: "Created", type: "date", width: 95 },
  { field: "leadType", title: "Comp Type", width: 90 },
  {
    title: "Current Status",
    render: (rowData) =>
      renderChip(rowData.acctStatus, statusToStyle, (s: string) =>
        internalToDisplayStatus(s, "transaction")
      ),
    customFilterAndSearch: (filter: any, rowData: Data) => {
      const value =
        internalToDisplayStatus(rowData.acctStatus, "transaction") || "";
      return value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    },
    customSort: (row1, row2) =>
      compareStrings(
        internalToDisplayStatus(row1.acctStatus, "transaction"),
        internalToDisplayStatus(row2.acctStatus, "transaction")
      ),
    width: 180,
  },
  {
    field: "repName",
    title: "Finance Manager",
    render: (rowData) => renderAvatar(rowData.repName),
    width: 115,
    cellStyle: { overflowWrap: "break-word" },
  },
  { field: "acctType", title: "Product", width: 90 },
  {
    field: "acctCost",
    title: "Loan Amount",
    type: "currency",
    cellStyle: centerAlignCellStyle,
    headerStyle: centerAlignCellStyle,
    width: 110,
  },
  {
    field: "pid",
    title: "PID",
    hidden: true,
    width: 110,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "vendorName",
    title: "Lead Source (VID)",
    width: 130,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "did",
    title: "DID",
    hidden: true,
    width: 110,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "rid",
    title: "RID",
    hidden: true,
    width: 110,
    cellStyle: { overflowWrap: "break-word" },
  },
  {
    field: "eid",
    title: "EID",
    hidden: true,
    width: 110,
    cellStyle: { overflowWrap: "break-word" },
  },
  { field: "lastTouch", title: "Last Touch", type: "date", width: 90 },
  { field: "nextTouch", title: "Next Touch", type: "date", width: 90 },
  { field: "acctOutlook", title: "Est. Fund Date", width: 135 },
  {
    field: "vendorSalesRep",
    title: "Vendor Sales Rep",
    hidden: true,
    width: 110,
  },
  {
    field: "lastUpdated",
    title: "Last Updated",
    hidden: true,
    searchable: false,
    defaultSort: "desc",
    type: "datetime",
    width: 100,
  },
];

const columnsPVD: Array<Column<Data>> = [
  {
    field: "pid",
    title: "PID",
    hidden: true,
    width: 110,
  },
  {
    field: "did",
    title: "DID",
    hidden: true,
    width: 110,
  },
  {
    field: "rid",
    title: "RID",
    hidden: true,
    width: 110,
  },
  {
    field: "eid",
    title: "EID",
    hidden: true,
    width: 110,
  },
];

export { columns, columnsPVD };

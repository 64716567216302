import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    iframe: {
      width: "100%",
      height: "99vh",
      border: "none",
    },
    integrationIframe: {
      height: "86vh",
      marginTop: "20px",
      [theme.breakpoints.down("md")]: {
        height: "99vh",
      },
    },
  })
);

export default useStyles;

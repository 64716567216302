import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { getAppInsights } from "../Services/telemetry";

const logError = (error: any, ...params: Array<any>) => {
  const appInsigths = getAppInsights();
  appInsigths &&
    appInsigths.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: params,
    });
};

const logInfo = (message: string, ...params: Array<any>) => {
  const appInsigths = getAppInsights();
  appInsigths &&
    appInsigths.trackTrace({
      message: message,
      severityLevel: SeverityLevel.Information,
      properties: params,
    });
};

const logger = { logError, logInfo };

export default logger;
